import styled from 'styled-components'

import { COLORS } from 'setup/theme'

export type TextHeadlineProps = {
  isHighlighted?: boolean
}

export const TextHeadline = styled.h2<TextHeadlineProps>`
  color: ${({ isHighlighted }) => (isHighlighted ? COLORS.blue : COLORS.white)};
  font-size: 2rem;
  line-height: 1.5;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`
