import { useCallback, useContext, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { toast } from 'react-toastify'

import { ROUTES } from 'routes/consts'
import { useSessionMutation } from 'setup/api/session/hooks'
import { ERROR_MESSAGES, TOKEN_KEY } from 'setup/consts'
import { RetrieveSession_retrieveSession } from 'setup/api/session/types/RetrieveSession'
import { useHistory } from 'react-router'
import SessionContext from './context'
import { useAdminToken } from 'providers/AdminTokenProvider/hooks'

export interface ISessionParams {
  EventID: string
}

export const useSessionContext = () => {
  const data = useContext(SessionContext)

  return data
}

export const useSessionData = () => {
  const [
    sessionData,
    setSessionData,
  ] = useState<RetrieveSession_retrieveSession | null>(null)
  const history = useHistory()

  const apolloClient = useApolloClient()
  const adminToken = useAdminToken()

  const [
    retrieveSessionMutation,
    retrieveSessionMutationOptions,
  ] = useSessionMutation({
    onError: (error) => {
      error.graphQLErrors.forEach(({ extensions }) => {
        const errorMessage = ERROR_MESSAGES[extensions?.code || 'DEFAULT']

        toast.error(errorMessage)
        clearSession()
      })
    },
  })

  const clearSession = useCallback(
    async (withoutRedirect?: boolean) => {
      await apolloClient.clearStore()

      localStorage.removeItem(TOKEN_KEY)
      setSessionData(null)

      if (!withoutRedirect) {
        history.replace(ROUTES.EVENTS)
      }
    },
    [apolloClient, history],
  )

  const retrieveSession = useCallback(
    async (eventId: string, isRetryingSession?: boolean) => {
      try {
        if (eventId) {
          const { data } = await retrieveSessionMutation({
            variables: {
              token: isRetryingSession
                ? undefined
                : localStorage.getItem(TOKEN_KEY),
              eventId: Number(eventId),
              isAdmin: !!adminToken,
            },
          })
          if (data && data.retrieveSession) {
            localStorage.setItem(TOKEN_KEY, data.retrieveSession?.token)

            setSessionData(data.retrieveSession)
          } else if (data && data.retrieveSession === null) {
            // Clear session without redirecting
            await clearSession(true)

            // If it's 1st time => retry without token
            if (!isRetryingSession) {
              retrieveSession(eventId, true)
            } else {
              // If it's 2nd time => redirect
              history.replace(ROUTES.EVENTS)
            }
          } else {
            await clearSession()
          }
        } else {
          await clearSession()
        }
      } catch (error) {
        await clearSession()
      }
    },
    [adminToken, clearSession, history, retrieveSessionMutation],
  )

  return {
    data: sessionData,
    setData: setSessionData,
    isLoading: retrieveSessionMutationOptions.loading,
    retrieveSession,
    clearSession,
  }
}
