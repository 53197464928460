import styled from 'styled-components'
import { COLORS } from 'setup/theme'

import { TextHeadline } from 'components/Typography/TextHeadline'
import { TextBody } from 'components/Typography/TextBody'
import { media } from 'common/MediaQueries'

export const UserFormWrapper = styled.div`
  margin: 2rem auto;
  padding-left: 2rem;
  padding-right: 2rem;

  @media ${media.phone} {
    max-width: 43rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  @media ${media.tablet} {
    max-width: 62rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

export const UserFormFields = styled.div`
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  @media ${media.phone} {
    margin-left: -1rem;
    margin-right: -1rem;
  }
`

export const InvoiceChoice = styled(TextBody)`
  display: flex;
  align-items: center;
`

export const Title = styled(TextHeadline)`
  color: ${COLORS.grey2};

  @media ${media.tablet} {
    color: ${COLORS.white};
  }
`
