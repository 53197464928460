import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router'

import CTAButton from 'components/CTAButton'
import Input from 'components/Input'
import Textarea from 'components/Textarea'
import Action from 'components/Action'
import Radio from 'components/Radio'
import Checkbox from 'components/Checkbox'
import { TextSmall } from 'components/Typography/TextSmall'
import { useSessionContext } from 'providers/SessionProvider/hooks'
import Container from 'components/Container'
import clsx from 'classnames'
import { EventParams } from 'setup/types'

import { UserFormWrapper, UserFormFields, InvoiceChoice, Title } from './styles'
import {
  INPUTS,
  CHECKBOXES,
  INVOICE_INPUTS,
  INVOICE_USER_INPUTS,
  PROVIDERS_OPTIONS,
  GUEST_CHECKBOXES,
} from './const'
import { useUserForm } from './hooks'
import Select from 'components/Select'
import { useAdminToken } from 'providers/AdminTokenProvider/hooks'
import SummaryTemplate from 'pages/Summary/Template'
import OrderDetailsTemplate from 'pages/Summary/OrderDetailsTemplate'
import { TransactionProvider } from 'setup/api/types/globalTypes'
import Inner from 'components/Inner'
import { filterVisibleFields } from './utils'

const UserForm = () => {
  const { data, retrieveSession } = useSessionContext()
  const { eventId } = useParams<EventParams>()
  const adminToken = useAdminToken()
  const {
    register,
    onSubmit,
    errors,
    isInvoiceRequired,
    invoiceValue,
    transactionProviderValue,
    isLoading,
    showSummary,
    orderDetails,
  } = useUserForm()

  useEffect(() => {
    if (eventId && !data) {
      retrieveSession(eventId)
    }
  }, [eventId, data, retrieveSession])

  const renderCheckbox = useCallback(
    ({ name, label, isRequired }) => (
      <Container mt={['1.5rem', '', '1rem']} key={name}>
        <TextSmall>
          <Checkbox
            className="full-w"
            key={name}
            name={name}
            label={label}
            isRequired={isRequired}
            register={register}
            error={errors[name]}
          />
        </TextSmall>
      </Container>
    ),
    [errors, register],
  )

  if (showSummary && !!data) {
    return <SummaryTemplate orderDetails={orderDetails} />
  }

  return (
    <>
      {!!adminToken && orderDetails && (
        <Container mt={['2rem', '', '4rem']}>
          <Inner className="text-center">
            <Title>Podsumowanie zamówienia</Title>
            <Container mt={['1rem']}>
              <OrderDetailsTemplate {...{ orderDetails }} />
            </Container>
          </Inner>
        </Container>
      )}
      <UserFormWrapper className="flex wrap center">
        <Container mb={['2rem', '', '4rem']}>
          <Title>Dane kupującego</Title>
        </Container>
        <Container>
          <form onSubmit={onSubmit}>
            <UserFormFields className="flex wrap">
              {INPUTS.filter(filterVisibleFields(!!adminToken)).map(
                ({ name, ...rest }) => (
                  <Input
                    key={name}
                    className="half-w"
                    name={name}
                    register={register}
                    error={errors[name]}
                    isRequired={
                      !adminToken ||
                      (transactionProviderValue ===
                        TransactionProvider.PRZELEWY24 &&
                        name === 'email')
                    }
                    {...rest}
                  />
                ),
              )}
            </UserFormFields>
            {!!adminToken && (
              <Container
                mt={['', '', '1rem']}
                mb={['', '', '3rem']}
                className="half-w"
              >
                <Select
                  name="transactionProvider"
                  label="Forma płatności"
                  register={register}
                  value={transactionProviderValue}
                  options={PROVIDERS_OPTIONS}
                  error={errors.transactionProvider}
                />
              </Container>
            )}
            <Container
              mt="1rem"
              mb={['1.5', '2rem', '3rem']}
              width="100%"
              display="flex"
              flexWrap={['wrap', 'nowrap']}
            >
              <Container
                width={['100%', 'auto']}
                mr={['', '2rem', '3rem']}
                mb={['0.75rem', '0']}
              >
                <InvoiceChoice>
                  <Checkbox
                    name="invoiceRequired"
                    label="Potrzebuję faktury"
                    register={register}
                  />
                </InvoiceChoice>
              </Container>
            </Container>
            {isInvoiceRequired && (
              <>
                <Container
                  mt="1rem"
                  mb={['1.5', '2rem', '3rem']}
                  width="100%"
                  display="flex"
                  flexWrap={['wrap', 'nowrap']}
                >
                  <Container
                    width={['100%', 'auto']}
                    mr={['', '2rem', '3rem']}
                    mb={['0.75rem', '0']}
                  >
                    <InvoiceChoice>
                      <Radio
                        defaultChecked
                        name="invoice"
                        value="company"
                        label="firma"
                        register={register}
                      />
                    </InvoiceChoice>
                  </Container>
                  <Container
                    width={['100%', '', 'auto']}
                    mr={['', '2rem', '3rem']}
                    mb={['0.75rem', '0']}
                  >
                    <InvoiceChoice>
                      <Radio
                        name="invoice"
                        value="personal"
                        label="osoba prywatna"
                        register={register}
                      />
                    </InvoiceChoice>
                  </Container>
                </Container>
                <Container mt="1rem" className="full-w">
                  <UserFormFields className="flex wrap">
                    {(invoiceValue === 'company'
                      ? INVOICE_INPUTS
                      : INVOICE_USER_INPUTS
                    )
                      .filter(filterVisibleFields(!!adminToken))
                      .map(({ name, small, full, fieldType, ...rest }) => {
                        const Field =
                          fieldType === 'textarea' ? Textarea : Input

                        return (
                          <Field
                            key={name}
                            className={clsx(
                              !!small && 'w-15p',
                              !!full && 'full-w',
                              !full && !small && 'half-w',
                            )}
                            name={name}
                            register={register}
                            error={errors[name]}
                            {...rest}
                          />
                        )
                      })}
                  </UserFormFields>
                </Container>
              </>
            )}
            <Container mt={['', '', '1rem']} mb={['', '', '3rem']}>
              {!adminToken && GUEST_CHECKBOXES.map(renderCheckbox)}
              {CHECKBOXES.filter(filterVisibleFields(!!adminToken)).map(
                renderCheckbox,
              )}
            </Container>
            <Action>
              <CTAButton type="submit" disabled={isLoading}>
                Kup bilet
              </CTAButton>
            </Action>
          </form>
        </Container>
      </UserFormWrapper>
    </>
  )
}

export default UserForm
