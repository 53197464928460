import {
  DocumentNode,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useMutation,
  useQuery,
} from '@apollo/client'

export const makeMutationHook = <TData = any, TVariables = OperationVariables>( // eslint-disable-line @typescript-eslint/no-explicit-any
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
): ((
  options?: MutationHookOptions<TData, TVariables>,
) => MutationTuple<TData, TVariables>) => (
  options?: MutationHookOptions<TData, TVariables>,
) => useMutation<TData, TVariables>(mutation, options)

export const makeQueryHook = <TData = any, TVariables = OperationVariables>( // eslint-disable-line @typescript-eslint/no-explicit-any
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
): ((
  options?: QueryHookOptions<TData, TVariables>,
) => QueryResult<TData, TVariables>) => (
  options?: QueryHookOptions<TData, TVariables>,
) => useQuery<TData, TVariables>(query, options)
