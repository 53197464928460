import React, { memo } from 'react'
import format from 'date-fns/format'
import isToday from 'date-fns/isToday'
import pl from 'date-fns/locale/pl'

import { DayWrapper, DayNumber, Day, Month } from './styles'

interface IDayBlock {
  date: Date
  isSelected: boolean
  isDisabled?: boolean
  onSelect: (newDate: Date) => void
}

const DayBlock = ({ date, isSelected, onSelect, isDisabled }: IDayBlock) => {
  const [dayName, monthName, day] = format(date, 'E/MMM/d', {
    locale: pl,
  }).split('/')

  const onClick = () => {
    if (!isDisabled) {
      onSelect(date)
    }
  }

  return (
    <DayWrapper
      isDisabled={!!isDisabled}
      isSelected={isSelected}
      onClick={onClick}
    >
      <Day>{isToday(date) ? 'dziś' : dayName}</Day>
      <DayNumber>{day}</DayNumber>
      <Month>{monthName}</Month>
    </DayWrapper>
  )
}

export default memo(DayBlock)
