import { gql } from '@apollo/client'

export const TICKET_FRAGMENT = gql`
  fragment Ticket on Ticket {
    id
    lockedSeatId
    name
    seatLabel
    ticketPoolId
    priceGross
    priceTaxRate
  }
`
