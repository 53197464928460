import styled from 'styled-components'
import { COLORS } from 'setup/theme'
import { transition } from 'common/mixins'
import { TextHeadline } from 'components/Typography/TextHeadline'
import { media } from 'common/MediaQueries'

export const SummaryWrapper = styled.div`
  margin: 2rem 0;
  text-align: center;

  @media ${media.tablet} {
    margin: 4rem 0;
  }
`

export const Title = styled(TextHeadline)`
  color: ${COLORS.grey2};
  margin-bottom: 2.25rem;

  @media ${media.phone} {
    margin-bottom: 3.375rem;
  }

  @media ${media.tablet} {
    margin-bottom: 4.5rem;
    color: ${COLORS.white};
  }
`

export const AddToCalendar = styled.a`
  display: flex;
  align-items: center;
  max-width: 20.5rem;
  width: 100%;
  justify-content: space-between;
  padding: 1.375rem;
  margin: 0 auto;
  border: 1px solid ${COLORS.white};
  height: 4rem;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  ${transition}

  svg {
    margin-left: 1rem;
  }

  &:hover {
    background: ${COLORS.white};
    color: ${COLORS.black};
  }
`

export const TicketsList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto 1.5rem auto;

  @media ${media.phone} {
    text-align: left;
    align-items: flex-start;
  }
`

export const TicketsItem = styled.li`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 0.375rem;
  }

  @media ${media.phone} {
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
`

export const Ticket = styled.div`
  @media ${media.phone} {
    min-width: 9.5rem;
  }
`

export const Seat = styled.div`
  margin-bottom: 0.125rem;

  @media ${media.phone} {
    min-width: 10.125rem;
    margin-bottom: 0;

    & + ${Ticket} {
      margin-left: 0.75rem;
    }
  }
`

export const Decor = styled.span`
  color: ${COLORS.grey2};
`
