export enum ButtonSize {
  default = 'default',
  small = 'small',
  auto = 'auto',
}

export enum ButtonType {
  default = 'default',
  simple = 'simple',
  simpleSmall = 'simpleSmall',
}
