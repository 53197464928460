import { gql } from '@apollo/client'

export const SHOW_FRAGMENT = gql`
  fragment Show on Show {
    id
    name
    descShort
    descLong
    duration
    status
  }
`
