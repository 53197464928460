import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { ROUTES } from 'routes/consts'
import { isMoreThanMinuteAgo } from 'utils/date'
import { EventSeatType } from 'setup/api/types/globalTypes'
import Seats from 'pages/Seats'
import { useSessionContext } from 'providers/SessionProvider/hooks'
import { EventParams } from 'setup/types'
import Tickets from 'pages/Tickets'

const EventDetails = () => {
  const { retrieveSession, data } = useSessionContext()
  const history = useHistory()
  const { eventId } = useParams<EventParams>()

  useEffect(() => {
    if (eventId) {
      retrieveSession(eventId)
    }
  }, [retrieveSession, eventId])

  useEffect(() => {
    if (data && data.event?.date) {
      if (isMoreThanMinuteAgo(new Date(data.event.date))) {
        history.replace(ROUTES.EVENTS)

        toast.error('Sprzedaż biletów na to wydarzenie nie jest dostępna.')
      }
    }
  }, [data, history])

  if (data && data.event?.seatType === EventSeatType.NUMERIC) {
    return <Seats />
  }
  if (data && data.event?.seatType === EventSeatType.NON_NUMERIC) {
    return <Tickets />
  }

  return null
}

export default EventDetails
