import styled from 'styled-components'
import { COLORS } from 'setup/theme'

import { media } from 'common/MediaQueries'
import { fieldReset } from 'common/mixins'

export const SelectField = styled.select`
  ${fieldReset}
  min-height: 3.625rem;
  padding: 1rem 2.5rem 1rem 1rem;
  font-family: ${({ theme }) => theme.fontFamilies.archia};
  color: ${COLORS.lightGrey};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  border: 1px solid ${COLORS.lightGrey};
  font-size: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;

  &:focus,
  &.active {
    color: ${COLORS.lightGrey};
    border-color: ${COLORS.lightGrey};
  }

  &:focus + span,
  &.active + span {
    color: ${COLORS.lightGrey};
  }

  option {
    color: ${COLORS.black};
  }

  @media ${media.phone} {
    padding-left: 1.5rem;
  }
`

export const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  color: ${COLORS.lightGrey};
`

export const DecorArrows = styled.span`
  position: absolute;
  top: 0;
  right: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.625rem;
  pointer-events: none;

  svg + svg {
    margin-top: 0.875rem;
  }
`

export const SelectControl = styled.div`
  position: relative;
  width: 100%;
`
