import React, { memo } from 'react'
import ContentLoader from 'react-content-loader'
import { COLORS } from 'setup/theme'

import Container from 'components/Container'

const SingleRow = () => {
  const cols = new Array(14).fill('0')

  return (
    <ContentLoader
      viewBox="0 0 620 32"
      backgroundColor={COLORS.grey}
      foregroundColor={COLORS.white}
    >
      {cols.map((_, index) => (
        <rect x={40 + index * 40} y="0" width="32" height="32" key={index} />
      ))}
    </ContentLoader>
  )
}

const SceneLoader = () => {
  const rows = new Array(13).fill('0')
  return (
    <Container className="mar-t-3" width="620px" style={{ maxWidth: '100%' }}>
      {rows.map((item, index) => (
        <SingleRow key={index} />
      ))}
    </Container>
  )
}

export default memo(SceneLoader)
