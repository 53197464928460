/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum EventSeatStatus {
  BOOKED_FINAL = "BOOKED_FINAL",
  BOOKED_TEMP = "BOOKED_TEMP",
  FREE = "FREE",
  LOCKED = "LOCKED",
}

export enum EventSeatType {
  NON_NUMERIC = "NON_NUMERIC",
  NUMERIC = "NUMERIC",
}

export enum EventStatus {
  DRAFT = "DRAFT",
  PRIVATE = "PRIVATE",
  PUBLISHED = "PUBLISHED",
}

export enum LockedSeatType {
  ADMIN = "ADMIN",
  CUSTOMER_FINAL = "CUSTOMER_FINAL",
  CUSTOMER_TEMP = "CUSTOMER_TEMP",
}

export enum OrderStatus {
  CANCELLED = "CANCELLED",
  NEW = "NEW",
  PAID = "PAID",
}

export enum QueryMode {
  DEFAULT = "DEFAULT",
  INSENSITIVE = "INSENSITIVE",
}

export enum SeatType {
  SEAT = "SEAT",
  SPACE = "SPACE",
}

export enum ShowStatus {
  DRAFT = "DRAFT",
  PRIVATE = "PRIVATE",
  PUBLISHED = "PUBLISHED",
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum TicketPoolStatus {
  DRAFT = "DRAFT",
  PRIVATE = "PRIVATE",
  PUBLISHED = "PUBLISHED",
}

export enum TransactionProvider {
  CARD = "CARD",
  CASH = "CASH",
  PRZELEWY24 = "PRZELEWY24",
  TRANSFER = "TRANSFER",
  VOUCHER = "VOUCHER",
}

export interface DateFilterInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  notIn?: any[] | null;
}

export interface EventFilterInput {
  date?: DateFilterInput | null;
  show?: ShowFilterInput | null;
  status?: EventStatus | null;
}

export interface EventOrderByInput {
  columnsNumber?: SortOrder | null;
  createdAt?: SortOrder | null;
  date?: SortOrder | null;
  deletedAt?: SortOrder | null;
  id?: SortOrder | null;
  location?: SortOrder | null;
  rowsNumber?: SortOrder | null;
  seatType?: SortOrder | null;
  status?: SortOrder | null;
  ticketLimit?: SortOrder | null;
  updatedAt?: SortOrder | null;
}

export interface LockedSeatCreateInput {
  seatId?: number | null;
}

export interface LockedSeatUpdateInput {
  seatId: number;
}

export interface OrderCreateInput {
  email: string;
  firstName: string;
  invoiceApartment?: string | null;
  invoiceCity?: string | null;
  invoiceCompany?: string | null;
  invoiceFirstName?: string | null;
  invoiceLastName?: string | null;
  invoiceStreet?: string | null;
  invoiceStreetNumber?: string | null;
  invoiceTaxNumber?: string | null;
  invoiceZIP?: string | null;
  isInvoiceBusiness?: boolean | null;
  isInvoiceRequested: boolean;
  isMarketingAgree: boolean;
  isPrivacyAgree: boolean;
  isTermsAgree: boolean;
  lastName: string;
  phone: string;
  returnUrl: string;
  transactionProvider?: TransactionProvider | null;
}

export interface ShowFilterInput {
  name?: StringFilterInput | null;
  status?: ShowStatus | null;
}

export interface StringFilterInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  in?: string[] | null;
  mode?: QueryMode | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TicketCreateInput {
  lockedSeatId: number;
  ticketPoolId: number;
}

export interface TicketUpdateInput {
  ticketPoolId: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
