import styled, { css } from 'styled-components'
import { COLORS, SIZES } from 'setup/theme'
import { media } from 'common/MediaQueries'
import { transition } from 'common/mixins'

export const LayoutWrapper = styled.div`
  width: 100%;
  color: ${COLORS.white};
`

export const HeaderWrapper = styled.header`
  position: relative;
  border-bottom: 1px solid ${COLORS.grey3};
  display: flex;
  height: ${SIZES.headerMobile};

  @media ${media.phone} {
    height: ${SIZES.headerTablet};
  }

  @media ${media.tablet} {
    height: ${SIZES.headerDesktop};
  }

  @media ${media.desktop} {
    height: ${SIZES.header};
  }
`

export const HeaderLogoWrapper = styled.div`
  border-right: 1px solid ${COLORS.grey3};
  width: 4.5rem;
  height: 100%;
  display: flex;
  flex-shrink: 0;

  @media ${media.phone} {
    width: 7.5rem;
  }

  @media ${media.tablet} {
    width: 12.5rem;
  }
`

export const HeaderNavigationWrapper = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.grey};
  overflow: hidden;

  @media ${media.tablet} {
    justify-content: center;
  }
`

export const FooterWrapper = styled.footer`
  color: ${COLORS.grey};
  height: ${SIZES.footer};
  display: flex;
  align-items: center;
  justify-content: center;
`
export const BodyWrapper = styled.div`
  min-height: calc(100vh - ${SIZES.footer} - ${SIZES.header});
`
export const NavigationList = styled.div`
  color: ${COLORS.white};
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;

  .swiper-wrapper {
    box-sizing: border-box;
  }

  .swiper-container {
    max-width: 10rem;
    display: flex;
    justify-content: space-between;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .swiper-button-prev,
  .swiper-button-next {
    margin: 0;
    background-image: none;
    position: absolute;
    top: 0;
    width: 1.5rem;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    &.swiper-button-disabled {
      opacity: 0.4;
    }
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }

  @media (max-width: 374px) {
    transform: translateX(-50%) translateX(5vw);
  }

  @media ${media.phone} {
    .swiper-button-prev,
    .swiper-button-next {
      &::before,
      &::after {
        width: 0.5rem;
        height: 1rem;
        background-size: 0.5rem 1rem;
      }
    }

    .swiper-container {
      max-width: 14.75rem;
    }
  }

  @media ${media.tablet} and (max-width: 1200px) {
    position: static;
    transform: translateX(-5vw);
  }
`

interface INavigationItem {
  isActive: boolean
  isDone: boolean
}

export const NavigationText = styled.span`
  display: block;
  font-size: 1.25rem;
  line-height: 1.75;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  text-transform: uppercase;
  ${transition}
`

export const NavigationItem = styled.div<INavigationItem>`
  ${({ isActive }) => `color: ${isActive ? COLORS.white : COLORS.grey}`};
  display: flex;
  align-items: center;
  justify-content: center;

  ${NavigationText} {
    ${({ isDone }) =>
      isDone &&
      css`
        color: ${COLORS.grey2};
      `};
  }

  a {
    &::before {
      display: none;
    }

    &:hover {
      ${NavigationText} {
        color: ${COLORS.lightGrey};
      }
    }
  }

  :not(:first-child):before {
    display: block;
    content: '';
    width: 2.5rem;
    height: 1px;
    background-color: ${COLORS.grey};
    margin: 10px;
  }
`

export interface ICol {
  size: 'small' | 'medium' | 'large' | 'full'
}

const SIZE_MAP: Record<ICol['size'], string> = {
  small: '25%',
  medium: '50%',
  large: '75%',
  full: '100%',
}

const getColWidth = (props: ICol) => {
  if (!props.size) {
    return '100%'
  }
  return SIZE_MAP[props.size]
}

export const Col = styled.div<ICol>`
  width: 100%;
  @media (min-width: 1020px) {
    width: ${(props) => getColWidth(props)};
  }
`

export const CloseButton = styled.button`
  position: fixed;
  top: 1.25rem;
  right: 0.5rem;
  padding: 0.5rem;
  display: flex;
  color: ${COLORS.white};
  background: ${COLORS.black};
  z-index: 10;
  cursor: pointer;
  ${transition}

  &:hover {
    color: ${COLORS.blue};
  }

  p {
    display: none;
  }

  @media ${media.phone} {
    top: 2.625rem;
    right: 2.75rem;
  }
`
