import { createContext } from 'react'

export interface IAppTypeContext {
  type: 'calendar' | 'schedule' | 'booking' | 'miniSchedule' | 'summary'
}

const AppTypeContext = createContext<IAppTypeContext>({
  type: 'calendar',
})

export default AppTypeContext
