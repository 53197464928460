import React from 'react'
import SessionContext from './context'
import { useSessionData } from './hooks'

const SessionProvider: React.FC = ({ children }) => {
  const value = useSessionData()

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  )
}

export default SessionProvider
