import React, { useEffect } from 'react'
import {
  concat,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider as ApolloClientProvider,
  ApolloLink,
} from '@apollo/client'

import { API_URL } from '../consts'
import { useAdminToken } from 'providers/AdminTokenProvider/hooks'

const httpLink = createHttpLink({
  uri: API_URL(),
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink,
})

export const generateAuthLink = (token: string): ApolloLink =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }: Record<string, any>) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }))

    return forward(operation)
  })

export const ApolloProvider: React.FC = ({ children }) => {
  const adminToken = useAdminToken()

  useEffect(() => {
    if (!!adminToken) {
      const authMiddleware = generateAuthLink(adminToken)

      client.setLink(concat(authMiddleware, httpLink))
    } else {
      client.setLink(httpLink)
    }
  }, [adminToken])

  return <ApolloClientProvider client={client}>{children}</ApolloClientProvider>
}
