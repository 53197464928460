import React, { ButtonHTMLAttributes } from 'react'

import { Button } from './styles'
import { ButtonType, ButtonSize } from './consts'

export interface ICTAButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: VoidFunction
  btnType?: ButtonType
  btnSize?: ButtonSize
}

const CTAButton: React.FC<ICTAButton> = ({
  children,
  btnType = ButtonType.default,
  btnSize = ButtonSize.default,
  onClick,
  ...rest
}) => (
  <Button {...rest} onClick={onClick} btnType={btnType} btnSize={btnSize}>
    {children}
  </Button>
)

export default CTAButton
