import styled from 'styled-components'

import {
  margin as m,
  padding as p,
  maxWidth,
  color,
  background as bg,
  width,
  display,
  flex,
  flexDirection,
  flexWrap,
  justifyContent,
  alignItems,
  alignContent,
  position,
  textAlign,
  MarginProps,
  PaddingProps,
  MaxWidthProps,
  ColorProps,
  BackgroundColorProps,
  WidthProps,
  DisplayProps,
  FlexboxProps,
  PositionProps,
  TextAlignProps,
} from 'styled-system'

interface IContainerProps
  extends MarginProps,
    PaddingProps,
    MaxWidthProps,
    ColorProps,
    BackgroundColorProps,
    WidthProps,
    DisplayProps,
    FlexboxProps,
    PositionProps,
    TextAlignProps {}

type IProps = IContainerProps

const Container = styled.div<IProps>`
  ${m}
  ${p}
  ${maxWidth}
  ${color}
  ${bg}
  ${width}
  ${display}
  ${flex}
  ${flexDirection}
  ${alignItems}
  ${alignContent}
  ${justifyContent}
  ${flexWrap}
  ${position}
  ${textAlign}
`

export default Container
