import React, { useContext } from 'react'
import { CloseButton } from './styles'

import { TextCaption } from 'components/Typography/TextCaption'
import CloseIcon from 'components/Icons/Close'
import AppTypeContext from 'providers/AppTypeProvider/context'

const Close = () => {
  const { type } = useContext(AppTypeContext)

  return (
    <CloseButton
      aria-label="Close"
      onClick={
        type === 'booking'
          ? window.REACT_INJECTORS.ejectBookingWidget
          : window.REACT_INJECTORS.ejectSummaryWidget
      }
    >
      <CloseIcon />
      <TextCaption>Zamknij</TextCaption>
    </CloseButton>
  )
}

export default Close
