import styled from 'styled-components'
import { COLORS } from 'setup/theme'

export const Label = styled.span`
  line-height: inherit;
  font-size: inherit;
  font-family: inherit;
`

export const StyledCheckbox = styled.span`
  position: relative;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  background: transparent;
  margin-right: 1rem;
  border: 1px solid ${COLORS.lightGrey};

  &::after {
    content: '';
    position: absolute;
    display: none;
    left: 0.3125rem;
    top: 0.0625rem;
    width: 0.25rem;
    height: 0.6rem;
    border: solid white;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
  }
`

export const Wrapper = styled.label<{ hasError: boolean }>`
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  align-items: inherit;
  cursor: pointer;
  ${({ hasError }) => !!hasError && `color: ${COLORS.red};`};
  ${StyledCheckbox} {
    ${({ hasError }) => !!hasError && `border-color: ${COLORS.red};`};
  }
`

export const CheckboxInput = styled.input`
  position: absolute;
  top: -100vh;
  left: -100vw;
  opacity: 0;
  height: 0;
  width: 0;
  visibility: hidden;

  &:checked + ${StyledCheckbox} {
    border-color: ${COLORS.white};

    &::after {
      display: block;
    }
  }
`
