import styled from 'styled-components'

import { COLORS } from 'setup/theme'

export const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9998;
  background: ${COLORS.black};
  overflow: auto;
`
