import styled from 'styled-components'
import { media } from 'common/MediaQueries'

export const ScheduleWrapper = styled.div`
  text-align: center;
`

export const LoaderWrapper = styled.div`
  width: 100%;
  max-width: 83rem;
  margin: 4rem auto auto;

  @media ${media.phone} {
    margin-top: 6rem;
  }

  @media ${media.tablet} {
    margin-top: 4rem;
  }
`
