import React from 'react'
import ContentLoader from 'react-content-loader'
import { COLORS } from 'setup/theme'

const TicketsLoader = () => {
  return (
    <ContentLoader
      viewBox="0 0 600 180"
      backgroundColor={COLORS.grey}
      foregroundColor={COLORS.white}
    >
      <rect x="20" y="20" rx="3" ry="3" width="500" height="14" />
      <rect x="20" y="50" rx="3" ry="3" width="500" height="14" />
      <rect x="20" y="80" rx="3" ry="3" width="80" height="14" />
      <rect x="20" y="120" width="100" height="40" />
    </ContentLoader>
  )
}

export default TicketsLoader
