import React, { useCallback } from 'react'

import Container from 'components/Container'
import Inner from 'components/Inner'
import Message from 'components/Message'

import { SummaryWrapper, Title } from './styles'
import Action from 'components/Action'
import CTAButton from 'components/CTAButton'
import { OrderStatus, TransactionProvider } from 'setup/api/types/globalTypes'
import { OrderDetails_order } from 'setup/api/order/types/OrderDetails'
import { PROVIDERS_OPTIONS } from 'pages/UserForm/const'
import { TextHeadline } from 'components/Typography/TextHeadline'
import LinkButton from 'components/LinkButton'
import { useAdminToken } from 'providers/AdminTokenProvider/hooks'
import { useSettings } from 'providers/SettingsProvider/hooks'
import OrderDetailsTemplate from './OrderDetailsTemplate'

export type SummaryTemplateProps = {
  orderDetails?:
    | (OrderDetails_order & { transactionProvider?: TransactionProvider })
    | null
  loading?: boolean
}

const SummaryTemplate = ({ loading, orderDetails }: SummaryTemplateProps) => {
  const adminToken = useAdminToken()
  const { onOrderDetailsClick } = useSettings()

  const onSeeDetails = useCallback(() => {
    if (orderDetails?.id) {
      onOrderDetailsClick?.(orderDetails?.id)
    }
  }, [onOrderDetailsClick, orderDetails])

  if (loading) {
    return (
      <SummaryWrapper>
        <Inner>Proszę czekać.</Inner>
      </SummaryWrapper>
    )
  }

  if (orderDetails) {
    return (
      <SummaryWrapper>
        <Inner>
          <Title>
            {orderDetails.status === OrderStatus.PAID
              ? 'Dziękujemy za zakup biletów!'
              : 'Oczekujemy na płatność...'}
          </Title>
          <Container mb={['', '4rem']}>
            <OrderDetailsTemplate {...{ orderDetails }} />
          </Container>
          {!!orderDetails.transactionProvider && (
            <Container mb={['', '4rem']}>
              <Container mt="0.25rem" mr="0.5rem">
                Forma płatności:
              </Container>
              <TextHeadline as="p">
                {
                  PROVIDERS_OPTIONS.find(
                    (item) => item.value === orderDetails.transactionProvider,
                  )?.label
                }
              </TextHeadline>
            </Container>
          )}
          <Action>
            <CTAButton onClick={window.REACT_INJECTORS.ejectBookingWidget}>
              Zamknij
            </CTAButton>
          </Action>
          {!!adminToken && (
            <Container mt="0.5rem">
              <Action>
                <LinkButton onClick={onSeeDetails}>
                  Szczegóły zamówienia
                </LinkButton>
              </Action>
            </Container>
          )}
        </Inner>
      </SummaryWrapper>
    )
  } else {
    return (
      <Container>
        <Inner>
          <Message>Ups! Coś poszło nie tak!</Message>
        </Inner>
      </Container>
    )
  }
}

export default SummaryTemplate
