import styled from 'styled-components'
import { media } from 'common/MediaQueries'

export const TicketsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 22rem;
  margin: 0 auto;

  @media ${media.tablet} {
    margin: 0;
    max-width: 100%;
  }
`

export const TicketsFields = styled.div`
  width: 100%;

  @media ${media.tablet} {
    padding-right: 3rem;
  }
`

export const TicketsRow = styled.div`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  @media ${media.phone} {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
`

export const TicketsBottom = styled.div`
  margin-top: 2rem;
  text-align: center;
  width: 100%;

  @media ${media.tablet} {
    text-align: left;

    button {
      min-width: 100%;
    }
  }
`
