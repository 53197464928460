import React from 'react'

import Logo from 'components/Logo'
import { HeaderWrapper, HeaderLogoWrapper } from './styles'

const HeaderBase: React.FC = ({ children }) => {
  return (
    <HeaderWrapper>
      <HeaderLogoWrapper>
        <Logo />
      </HeaderLogoWrapper>
      {children}
    </HeaderWrapper>
  )
}

export default HeaderBase
