import React, { useMemo } from 'react'

import { Route, RouteProps } from 'react-router-dom'

import Layout from 'components/layout'

const withLayout = (Component: any) => (routerProps: RouteProps) => (
  <Layout>
    <Component {...routerProps} />
  </Layout>
)

const LayoutRoute = (props: RouteProps) => {
  const { component: Component, ...rest } = props
  const Child = useMemo(() => withLayout(Component), [Component])

  return <Route component={Child} {...rest} />
}

export default LayoutRoute
