import React from 'react'

import EventCard from 'components/EventCard'
import Container from 'components/Container'

import { TicketsList, TicketsItem, Ticket, Seat, Decor } from './styles'
import TotalPrice from 'components/TotalPrice'
import { TextCaption } from 'components/Typography/TextCaption'
import { parseAmount } from 'utils/string'
import { ROMAN_DIGITS } from 'components/SeatsPicker/const'
import { EventSeatType } from 'setup/api/types/globalTypes'
import { OrderDetails_order } from 'setup/api/order/types/OrderDetails'

const getRowName = (order?: number, columnsCount?: number) => {
  return ROMAN_DIGITS[Math.floor((order || 0) / (columnsCount || 1))]
}

export type OrderDetailsTemplateProps = {
  orderDetails: OrderDetails_order
}

const OrderDetailsTemplate = ({ orderDetails }: OrderDetailsTemplateProps) => (
  <>
    <Container mb={['', '2.5rem']}>
      <EventCard event={orderDetails.event!} />
    </Container>
    {orderDetails.tickets?.nodes &&
      orderDetails.tickets.nodes.map((item) => (
        <TicketsList key={item.id}>
          <TicketsItem>
            {orderDetails.event?.seatType === EventSeatType.NUMERIC && (
              <Seat>
                <Decor>Miejsce: </Decor> {item.lockedSeat?.seat?.label} &nbsp;
                <Decor>Rząd: </Decor>{' '}
                {getRowName(
                  item.lockedSeat?.seat?.order,
                  orderDetails?.event?.columnsNumber,
                )}
              </Seat>
            )}
            <Ticket>
              <TextCaption>
                <Decor>Bilet:</Decor> {item.name} (
                {parseAmount(item.priceGross)} zł)
              </TextCaption>
            </Ticket>
          </TicketsItem>
        </TicketsList>
      ))}
    <Container>
      <TotalPrice total={orderDetails.amountGross} />
    </Container>
  </>
)

export default OrderDetailsTemplate
