import styled from 'styled-components'
import { COLORS } from 'setup/theme'
import { dashDecor } from 'common/mixins'

import { EVENT_TYPES } from 'components/EventsList/consts'

export interface ILink {
  eventType?: EVENT_TYPES
  isActive?: boolean
  onClick?: VoidFunction
  className?: string
}

export const Link = styled.button<ILink>`
  color: ${COLORS.grey2};
  font-size: 1rem;
  line-height: 1.75;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  border: 0;
  background: none;
  cursor: pointer;
  outline: 0;
  ${dashDecor}
  transition: color 250ms ease-in-out;

  :hover {
    color: ${COLORS.white};
  }
  :active {
    outline: 0;
  }

  ${({ eventType, isActive }) =>
    eventType === EVENT_TYPES.repertoire &&
    `
    line-height: 1.25;
    font-size: 1.5rem;
    color: ${isActive ? COLORS.white : COLORS.grey};
  `}

  ${({ eventType, isActive }) =>
    eventType === EVENT_TYPES.repertoire &&
    !isActive &&
    `
    background: none;
  `}
`
