export const API_URL = () => window.TF_API_URL || ''
export const TERMS_URL = () => window.TF_TERMS_URL || '/regulamin'

export const TOKEN_KEY = 'TF_WIDGET_TOKEN'
export const ADMIN_TOKEN_KEY = 'TF_ADMIN_TOKEN'
export const REFRESHING_TOKEN_KEY = 'TF_WIDGET_TOKEN_REFRESH'
export const PATH_PREFIX = 'tf'

export const ERROR_MESSAGES: Record<string, string> = {
  TICKET_BOOKING_LIMIT_EXCEEDED:
    'Osiągnięto limit miejsc dla jednej transakcji.',
  NOT_FOUND: 'Wydarzenie niedostępne.',
  EXPIRED: 'Sesja wygasła. Przekierowujemy do Kalendarza wydarzeń',
  DEFAULT: 'Coś poszło nie tak. Spróbuj ponownie później.',
  UNAUTHORIZED: 'Brak uprawnień do wykonania tej akcji.',
}

export const API_DATE_FORMAT = 'yyyy-MM-dd'
export const API_SHORT_DATE_FORMAT = 'yyyy-MM'

export const APP_ID = {
  BOOKING: 'buy-ticket-widget',
  SUMMARY: 'summary-widget',
  CALENDAR: 'calendar-widget',
  SCHEDULE: 'schedule-widget',
  MINI_SCHEDULE: 'mini-schedule-widget',
  MINI_SCHEDULE_MOBILE: 'mini-schedule-widget-mobile',
}

export const REFRESH_DATA_EVENT = 'TF_REFRESH_EVENT'
