import React, { memo, ChangeEvent, useCallback } from 'react'

import LinkButton from 'components/LinkButton'
import {
  SelectField,
  SelectWrapper,
  SelectControl,
  Action,
  DecorArrows,
} from './styles'
import ArrowUp from 'components/Icons/ArrowUp'
import ArrowDown from 'components/Icons/ArrowDown'
import { Session_event_ticketPools_nodes } from 'setup/api/session/types/Session'
import { parseAmount } from 'utils/string'

interface ITicketDropdownProps {
  group?: Session_event_ticketPools_nodes[]
  onChange: (
    lockedSeatId: number,
    ticketPoolId: string,
    ticketId?: number,
  ) => void
  onRemove?: (lockedSeatId: number) => void
  value: string
  disabled?: boolean
  lockedSeatId: number
  ticketId?: number
}

const TicketDropdown = ({
  group = [],
  onChange,
  onRemove,
  value,
  disabled,
  lockedSeatId,
  ticketId,
}: ITicketDropdownProps) => {
  const onSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      onChange(lockedSeatId, event.target.value, ticketId)
    },
    [lockedSeatId, onChange, ticketId],
  )

  const onRemoveClick = useCallback(() => {
    if (onRemove) {
      onRemove(lockedSeatId)
    }
  }, [lockedSeatId, onRemove])

  const isOptionDisabled = useCallback(
    (ticketsLeft: number) => ticketsLeft === 0,
    [],
  )
  const isUnavailable = useCallback(
    (ticketsLeft: number, id: number) => `${id}` !== value && ticketsLeft === 0,
    [value],
  )

  return (
    <SelectWrapper>
      <SelectControl>
        <SelectField
          disabled={disabled}
          onChange={onSelectChange}
          value={value}
          className={`full-w mar-b-1 ${value !== '-1' ? 'active' : ''}`}
        >
          <option disabled value={'-1'}>
            {group.length > 0 && 'Wybierz bilet'}
            {group.length === 0 && 'Brak biletów'}
          </option>
          {group.map((item) => (
            <option
              key={item.id}
              value={item.id}
              disabled={isOptionDisabled(item.ticketLeft)}
            >
              {item.name} - {parseAmount(item.priceGross)} zł
              {isUnavailable(item.ticketLeft, item.id) ? ' (Niedostępny)' : ''}
            </option>
          ))}
        </SelectField>
        <DecorArrows>
          <ArrowUp />
          <ArrowDown />
        </DecorArrows>
      </SelectControl>
      {!!onRemove && (
        <Action>
          <LinkButton onClick={onRemoveClick}>usuń</LinkButton>
        </Action>
      )}
    </SelectWrapper>
  )
}

export default memo(TicketDropdown)
