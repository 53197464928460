import React from 'react'

import { TotalPriceContainer } from './styles'
import Container from 'components/Container'
import { TextHeadline } from 'components/Typography/TextHeadline'
import { parseAmount } from 'utils/string'

interface ITotalPriceProps {
  total: number
}

const TotalPrice = ({ total }: ITotalPriceProps) => (
  <TotalPriceContainer>
    <Container mt="0.25rem" mr="0.5rem">
      CENA ŁĄCZNA:
    </Container>
    <TextHeadline as="p">{parseAmount(total)} zł</TextHeadline>
  </TotalPriceContainer>
)

export default TotalPrice
