import React from 'react'

import { useSummary } from './hooks'

import SummaryTemplate from './Template'

const Summary = () => {
  const { orderDetails, loading } = useSummary()

  return <SummaryTemplate {...{ orderDetails, loading }} />
}

export default Summary
