import { matchPath } from 'react-router'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import './index.css'

// IMPORTANT! DO NOT MOVE THIS IMPORT AS IT CONTAINS SOME DATA THAT IS USED IN DEV ENVS
import 'setup/window'

// import * as serviceWorker from './serviceWorker'
import { ROUTES } from 'routes/consts'
import injectors from 'setup/injectors'
import { PATH_PREFIX } from 'setup/consts'

if (process.env.REACT_APP_SENTRY_URL && process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new Integrations.BrowserTracing()],

    tracesSampleRate: 1.0,
  })
}

window.REACT_INJECTORS = injectors

if (window.location.hash) {
  const isBookingPath = Object.values(ROUTES).some((path) =>
    matchPath(window.location.hash.replace(`#/${PATH_PREFIX}`, ''), {
      path,
    }),
  )

  if (isBookingPath) {
    injectors.injectBuyTicketWidget()
  }
}

const elements = document.querySelectorAll(`[href*="#/${PATH_PREFIX}"`)

elements.forEach((element) => {
  ;(element as HTMLAnchorElement).href = `#${
    (element as HTMLAnchorElement).href.split('#')[1]
  }`

  element.addEventListener('click', () => {
    injectors.injectBuyTicketWidget()
  })
})

// serviceWorker.unregister()
