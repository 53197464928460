import React from 'react'

import AppTypeContext, { IAppTypeContext } from './context'

type IAppTypeProvider = IAppTypeContext & { children: React.ReactNode }

const AppTypeProvider = ({ children, type }: IAppTypeProvider) => {
  return (
    <AppTypeContext.Provider value={{ type }}>
      {children}
    </AppTypeContext.Provider>
  )
}

export default AppTypeProvider
