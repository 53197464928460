import React from 'react'
import { HashRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { ThemeProvider } from 'styled-components'

import 'react-toastify/dist/ReactToastify.css'

import Routes from 'routes'
import SessionProvider from 'providers/SessionProvider'
import AppTypeProvider from 'providers/AppTypeProvider'
import AdminTokenProvider from 'providers/AdminTokenProvider'
import SettingsProvider from 'providers/SettingsProvider'
import GlobalStyles from 'common/GlobalStyles'
import theme from 'setup/theme'
import { PATH_PREFIX } from 'setup/consts'
import { ApolloProvider } from 'setup/api/apollo'
import { StyledTooltip } from './styles'

export type AppPropsT = {
  adminToken?: string
  eventId?: string
  onOrderDetailsClick?: (orderId: number) => void
}

function App(props: AppPropsT) {
  return (
    <AdminTokenProvider token={props.adminToken}>
      <ApolloProvider>
        <SettingsProvider onOrderDetailsClick={props.onOrderDetailsClick}>
          <ThemeProvider theme={theme}>
            <HashRouter basename={PATH_PREFIX}>
              <AppTypeProvider type="booking">
                <SessionProvider>
                  <GlobalStyles />
                  <Routes eventId={props.eventId} />
                  <StyledTooltip multiline />
                </SessionProvider>
              </AppTypeProvider>
              <ToastContainer />
            </HashRouter>
          </ThemeProvider>
        </SettingsProvider>
      </ApolloProvider>
    </AdminTokenProvider>
  )
}

export default App
