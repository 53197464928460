import styled from 'styled-components'

import { COLORS } from 'setup/theme'

export const Link = styled.a`
  color: #fff;
  text-decoration: underline;
  transition: color 150ms ease-in-out;
  :hover {
    color: ${COLORS.blue};
    text-decoration: underline;
  }
`
