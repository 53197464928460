import { useMemo, useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'

import { useCreateOrderMutation } from 'setup/api/order/hooks'
import { getPaymentReturnUrl } from 'routes/consts'
import { useSessionContext } from 'providers/SessionProvider/hooks'
import { useAdminToken } from 'providers/AdminTokenProvider/hooks'
import { REFRESH_DATA_EVENT } from 'setup/consts'
import { OrderStatus, TransactionProvider } from 'setup/api/types/globalTypes'
import { SummaryTemplateProps } from 'pages/Summary/Template'

const getInvoiceFields = (data: any) => {
  if (!data.invoiceRequired) {
    return { isInvoiceRequested: false }
  }

  const sharedFields = {
    invoiceCity: data.city,
    invoiceZIP: data.zip,
    invoiceStreet: data.street,
    invoiceStreetNumber: data.streetNumber,
    invoiceApartment: data.apartment,
    isInvoiceRequested: true,
    isInvoiceBusiness: data.invoice === 'company',
    invoiceNote: data.invoiceNote,
  }

  if (data.invoice === 'company') {
    return {
      invoiceCompany: data.companyName,
      invoiceTaxNumber: data.nip,
      ...sharedFields,
    }
  }

  if (data.invoice === 'personal') {
    return {
      invoiceFirstName: data.firstName,
      invoiceLastName: data.lastName,
      ...sharedFields,
    }
  }

  return sharedFields
}

export const useUserForm = () => {
  const { data: sessionData } = useSessionContext()
  const adminToken = useAdminToken()
  const [orderId, setOrderId] = useState<number>()

  const [showSummary, setShowSummary] = useState(false)

  const { register, handleSubmit, errors, watch, setError } = useForm()

  const [createOrder, createOrderParams] = useCreateOrderMutation({
    context: adminToken
      ? {
          headers: { Authorization: `Bearer ${adminToken}` },
        }
      : {},
    onCompleted: (data) => {
      if (adminToken && !data.createOrder?.paymentUrl) {
        window.dispatchEvent(new CustomEvent(REFRESH_DATA_EVENT))

        setOrderId(data?.createOrder?.id)
        setShowSummary(true)

        return
      }
      if (data.createOrder?.paymentUrl) {
        window.location.href = data.createOrder?.paymentUrl
      } else {
        toast.error('Coś poszło nie tak. Proszę spróbuj ponownie.')
      }
    },
    onError: (error) => {
      error.graphQLErrors.forEach((item) => {
        toast.error(
          item.message || 'Coś poszło nie tak. Proszę spróbuj ponownie.',
        )
      })
    },
  })
  const transactionProvider = watch(
    'transactionProvider',
    !!adminToken ? TransactionProvider.CARD : TransactionProvider.PRZELEWY24,
  ) as TransactionProvider

  const emailValue = watch('email', '')
  const invoiceRequiredValue = watch('invoiceRequired', false)
  const invoiceValue = watch('invoice', 'company')

  const orderDetails: SummaryTemplateProps['orderDetails'] | null = useMemo(
    () =>
      !!sessionData
        ? {
            __typename: 'Order',
            id: orderId || 0,
            token: '',
            amountGross: sessionData!.amountGross,
            event: sessionData!.event,
            transactionProvider,
            status: OrderStatus.PAID,
            paymentUrl: '',
            tickets: {
              __typename: 'TicketPagination',
              nodes:
                sessionData?.lockedSeats?.nodes?.map((item) => ({
                  ...item.ticket!,
                  lockedSeat: item,
                })) || [],
            },
          }
        : null,
    [orderId, sessionData, transactionProvider],
  )

  const validateEmailConfirm = useCallback(
    (value: string) => {
      if (emailValue !== value) {
        setError('email2', {
          type: 'manual',
          message: 'Emaile się nie zgadzają',
        })
        return false
      }
      return true
    },
    [emailValue, setError],
  )

  const onSubmit = useCallback(
    async (data: any) => {
      if (createOrderParams.loading || !sessionData) return

      if (validateEmailConfirm(data.email2)) {
        const input = {
          firstName: data.fname,
          lastName: data.lname,
          phone: data.phone,
          email: data.email,
          returnUrl: getPaymentReturnUrl(),
          isTermsAgree: !!adminToken ? true : data.termsAgreement,
          isPrivacyAgree: data.privacyAgreement,
          isMarketingAgree: data.marketingAgreement,
          ...getInvoiceFields(data),
          transactionProvider: adminToken
            ? data.transactionProvider
            : TransactionProvider.PRZELEWY24,
        }

        createOrder({
          variables: {
            token: sessionData.token,
            input,
          },
        })
      }
    },
    [
      createOrderParams.loading,
      sessionData,
      validateEmailConfirm,
      adminToken,
      createOrder,
    ],
  )

  const onSubmitCallback = useMemo(() => handleSubmit(onSubmit), [
    handleSubmit,
    onSubmit,
  ])

  return {
    onSubmit: onSubmitCallback,
    register,
    errors,
    isInvoiceRequired: !!invoiceRequiredValue,
    isInvoiceSelected: !!invoiceValue,
    invoiceValue,
    transactionProviderValue: transactionProvider as TransactionProvider,
    isLoading: createOrderParams.loading,
    showSummary,
    orderDetails,
  }
}
