import React, { useMemo } from 'react'
import format from 'date-fns/format'

import { firstToUpper } from 'utils/string'
import { TextHeadline } from 'components/Typography/TextHeadline'
import { TextCaption } from 'components/Typography/TextCaption'
import Container from 'components/Container'

import { CardWrapper } from './styles'
import { formatToLongDisplayDate } from 'utils/date'

interface IEventCardProps {
  event: {
    date: string
    show?: {
      name: string
    } | null
    location?: string
  }
  isHighlighted?: boolean
  showLocation?: boolean
}

const EventCard = ({ event, isHighlighted, showLocation }: IEventCardProps) => {
  const formattedDate = useMemo(
    () => ({
      date: formatToLongDisplayDate(new Date(event.date)),
      hour: format(new Date(event.date), 'HH:mm'),
    }),
    [event.date],
  )

  return (
    <CardWrapper>
      <Container>
        <Container mb={['0.75rem', '0.375rem']}>
          <TextHeadline isHighlighted={isHighlighted}>
            {event.show?.name}
          </TextHeadline>
        </Container>
      </Container>
      {showLocation && (
        <Container>
          <Container mb={['0.75rem', '0.375rem']}>
            <TextHeadline>{event.location}</TextHeadline>
          </Container>
        </Container>
      )}
      <Container mb={['2rem', '', '2.5rem']}>
        <TextCaption>
          {firstToUpper(formattedDate.date)}, godz: {formattedDate.hour}
        </TextCaption>
      </Container>
    </CardWrapper>
  )
}

export default EventCard
