import { makeMutationHook } from 'setup/api/apollo-utils'
import { RETRIEVE_SESSION } from './mutations'
import {
  RetrieveSession,
  RetrieveSessionVariables,
} from './types/RetrieveSession'

export const useSessionMutation = makeMutationHook<
  RetrieveSession,
  RetrieveSessionVariables
>(RETRIEVE_SESSION)
