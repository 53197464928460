import { useContext, useMemo, useCallback } from 'react'
import { toast } from 'react-toastify'
import { generatePath, useHistory, useParams } from 'react-router'

import SessionContext from 'providers/SessionProvider/context'

import { ROUTES } from 'routes/consts'
import { EventParams } from 'setup/types'
import {
  useCreateTicketMutation,
  useUpdateTicketMutation,
} from 'setup/api/tickets/hooks'
import { CreateTicket } from 'setup/api/tickets/types/CreateTicket'
import { UpdateTicket } from 'setup/api/tickets/types/UpdateTicket'
import { ApolloError } from '@apollo/client'
import { useAdminToken } from 'providers/AdminTokenProvider/hooks'

export const useTicketsUtils = () => {
  const { data, clearSession, setData, retrieveSession } = useContext(
    SessionContext,
  )
  const { eventId } = useParams<EventParams>()
  const history = useHistory()

  const adminToken = useAdminToken()

  // On GQL error
  const onErrorCallback = useCallback(
    (error: ApolloError) => {
      toast.error('Nie udało się przypisać biletu do tego miejsca.')

      if (eventId) {
        retrieveSession(eventId)
      }
    },
    [eventId, retrieveSession],
  )

  // On GQL success - update session
  const onSuccessCallback = useCallback(
    (data: CreateTicket | UpdateTicket) => {
      const sessionData =
        (data as UpdateTicket).updateTicket ||
        (data as CreateTicket).createTicket

      if (sessionData) {
        setData(sessionData)
      }
    },
    [setData],
  )

  const mutationParams = useMemo(
    () => ({
      context: adminToken
        ? {
            headers: { Authorization: `Bearer ${adminToken}` },
          }
        : {},
      onCompleted: onSuccessCallback,
      onError: onErrorCallback,
    }),
    [adminToken, onErrorCallback, onSuccessCallback],
  )

  const [createTicket, createTicketParams] = useCreateTicketMutation(
    mutationParams,
  )
  const [updateTicket, updateTicketParams] = useUpdateTicketMutation(
    mutationParams,
  )

  // Change ticket value
  const changeTicket = useCallback(
    (lockedSeatId: number, ticketPoolId: string, ticketId?: number) => {
      if (data) {
        if (ticketId) {
          updateTicket({
            variables: {
              id: ticketId,
              token: data?.token,
              input: {
                ticketPoolId: Number(ticketPoolId),
              },
              isAdmin: !!adminToken,
            },
          })
        } else {
          createTicket({
            variables: {
              token: data?.token,
              input: {
                lockedSeatId,
                ticketPoolId: Number(ticketPoolId),
              },
              isAdmin: !!adminToken,
            },
          })
        }
      } else {
        clearSession()
      }
    },
    [adminToken, clearSession, createTicket, data, updateTicket],
  )

  const goToOrder = useCallback(() => {
    history.push(generatePath(ROUTES.FORM, { eventId }))
  }, [eventId, history])

  return {
    changeTicket,
    isLoading: createTicketParams.loading || updateTicketParams.loading,
    goToOrder,
  }
}
