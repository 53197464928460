import React, { useContext } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import AppTypeContext from 'providers/AppTypeProvider/context'

interface ILink {
  children: React.ReactNode
  to: string
  onClick?: (event: React.MouseEvent) => void
}

const Link = ({ children, ...props }: ILink) => {
  const { type } = useContext(AppTypeContext)

  if (type === 'booking') {
    return <RouterLink {...props}>{children}</RouterLink>
  }
  return <span {...props}>{children}</span>
}

export default Link
