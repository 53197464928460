import React from 'react'

import Seat from 'components/Seat'
import { Space, RowNumber } from 'components/Seat/styles'
import { SeatPickerContent } from './styles'
import { ROMAN_DIGITS } from './const'
import Container from 'components/Container'
import { useSeatsPickerData } from './hooks'
import { EventSeatStatus } from 'setup/api/types/globalTypes'
import { useAdminToken } from 'providers/AdminTokenProvider/hooks'

const SeatsPicker = () => {
  const {
    data,
    seatsArray,
    lockedSeatsMap,
    onSeatClick,
    isSeatUpdating,
  } = useSeatsPickerData()
  const adminToken = useAdminToken()

  if (!data) {
    return null
  }

  const availableStatuses = !!adminToken
    ? [EventSeatStatus.FREE, EventSeatStatus.LOCKED]
    : [EventSeatStatus.FREE]

  return (
    <>
      <SeatPickerContent>
        {seatsArray.map((cols, index) => (
          <Container
            width="100%"
            mt="1rem"
            className="flex"
            key={ROMAN_DIGITS[index]}
          >
            <RowNumber>{ROMAN_DIGITS[index]}</RowNumber>
            {cols.map((seat, seatIndex) =>
              seat && seat.type === 'SEAT' ? (
                <Seat
                  isDisabled={isSeatUpdating}
                  key={seat.id}
                  seatInfo={seat}
                  onClick={onSeatClick}
                  isBooked={seat.status === EventSeatStatus.LOCKED}
                  isTaken={
                    !lockedSeatsMap[seat.id] &&
                    !availableStatuses.includes(seat.status)
                  }
                  isSelected={!!lockedSeatsMap[seat.id]}
                />
              ) : (
                <Space key={`${index}=${seatIndex}`}>&nbsp;</Space>
              ),
            )}
            <RowNumber>{ROMAN_DIGITS[index]}</RowNumber>
          </Container>
        ))}
      </SeatPickerContent>
    </>
  )
}

export default SeatsPicker
