import { gql } from '@apollo/client'
import { EVENT_FRAGMENT } from '../events/fragments'
import { EVENT_SEAT_FRAGMENT } from '../eventSeats/fragments'
import { LOCKED_SEAT_FRAGMENT } from '../lockedSeats/fragments'
import { SHOW_FRAGMENT } from '../show/fragments'
import { TICKET_POOL_FRAGMENT } from '../ticketPools/fragments'
import { TICKET_FRAGMENT } from '../tickets/fragments'

export const SESSION_FRAGMENT = gql`
  ${EVENT_FRAGMENT}
  ${LOCKED_SEAT_FRAGMENT}
  ${EVENT_SEAT_FRAGMENT}
  ${SHOW_FRAGMENT}
  ${TICKET_POOL_FRAGMENT}
  ${TICKET_FRAGMENT}
  fragment Session on Session {
    id
    expireAt
    eventId
    token
    amountGross
    lockedSeats {
      nodes {
        ...LockedSeat
        ticket {
          ...Ticket
        }
        seat {
          label
          order
          id
        }
      }
    }
    event {
      ...Event
      eventSeats {
        nodes {
          ...EventSeat
          comments @include(if: $isAdmin)
        }
      }
      adminTicketPools: ticketPools(filter: { status: PUBLISHED })
        @include(if: $isAdmin) {
        nodes {
          ...TicketPool
        }
      }
      ticketPools(filter: { isOnline: { equals: true }, status: PUBLISHED })
        @skip(if: $isAdmin) {
        nodes {
          ...TicketPool
        }
      }
      show {
        ...Show
      }
    }
  }
`
