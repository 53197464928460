import React from 'react'
import { SvgComponent } from 'definitions/Svg'

const ArrowUp: SvgComponent = (props) => (
  <svg width="8.651" height="5.661" {...props}>
    <path
      d="M7.951 4.946L5.987 3.025 4.325 1.399.699 4.946"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
)

export default ArrowUp
