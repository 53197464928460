import React from 'react'

import EventCard from 'components/EventCard'
import SceneLoader from 'components/SeatsPicker/SceneLoader'
import SeatsPicker from 'components/SeatsPicker'
import Legend from 'components/Legend'
import Tickets from 'components/Tickets'
import { useSessionContext } from 'providers/SessionProvider/hooks'

import { SeatsWrapper, SeatsContent, SeatsInfo, SeatsTickets } from './styles'

const Seats = () => {
  const { data, isLoading } = useSessionContext()

  return (
    <SeatsWrapper>
      <SeatsInfo>
        {!!data && !!data.event && (
          <EventCard event={data.event} isHighlighted />
        )}
      </SeatsInfo>
      <SeatsContent>
        {!!data && !isLoading ? <SeatsPicker /> : <SceneLoader />}
      </SeatsContent>
      <Legend />
      <SeatsTickets>{!!data && <Tickets />}</SeatsTickets>
    </SeatsWrapper>
  )
}

export default Seats
