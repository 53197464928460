import { gql } from '@apollo/client'

import { SESSION_FRAGMENT } from '../session/fragments'

export const CREATE_TICKET = gql`
  ${SESSION_FRAGMENT}
  mutation CreateTicket(
    $token: String!
    $input: TicketCreateInput!
    $isAdmin: Boolean!
  ) {
    createTicket(sessionToken: $token, input: $input) {
      ...Session
    }
  }
`

export const UPDATE_TICKET = gql`
  ${SESSION_FRAGMENT}
  mutation UpdateTicket(
    $id: Int!
    $token: String!
    $input: TicketUpdateInput!
    $isAdmin: Boolean!
  ) {
    updateTicket(id: $id, sessionToken: $token, input: $input) {
      ...Session
    }
  }
`
