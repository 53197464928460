import styled, { css } from 'styled-components'
import { COLORS } from 'setup/theme'
import { media, customMedia } from 'common/MediaQueries'

import { ISeatType } from './consts'

interface ILegend {
  seatKind: ISeatType
}

export const LegendList = styled.ul`
  display: flex;
  justify-content: center;
  margin-top: 3rem;

  @media ${media.tablet} {
    display: block;
    position: absolute;
    margin-top: 0;
    width: 15rem;
    bottom: 0;
    left: 1.5rem;
  }

  @media ${customMedia.scene} {
    width: 20.5rem;
  }
`

export const LegendItem = styled.li`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: 0.625rem;
  flex: 1;

  :not(:last-child) {
    margin-right: 1rem;
  }

  @media ${media.phone} {
    flex-direction: row;
    font-size: 1rem;
    flex: auto;

    :not(:last-child) {
      margin-right: 3rem;
    }
  }

  @media ${media.tablet} {
    :not(:last-child) {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
`

export const LegendSeat = styled.span<ILegend>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin-bottom: 0.375rem;
  border: 1px solid currentColor;
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};

  ${({ seatKind }) =>
    seatKind === 'isTaken' &&
    css`
      color: ${COLORS.grey};
    `}

  ${({ seatKind }) =>
    seatKind === 'free' &&
    css`
      color: ${COLORS.white};
    `}

  ${({ seatKind }) =>
    seatKind === 'isBooked' &&
    css`
      color: ${COLORS.yellow};
      border-color: ${COLORS.yellow};
    `}

  ${({ seatKind }) =>
    seatKind === 'selected' &&
    css`
      color: ${COLORS.black};
      background: ${COLORS.white};
      border-color: ${COLORS.white};
    `}

    @media ${media.phone} {
      height: 2rem;
      margin-right: 1rem;
      margin-bottom: 0;
    }

    @media ${media.tablet} {
      margin-right: 1.5rem;
    }

`
