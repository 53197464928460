import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { media } from 'common/MediaQueries'

export const LogoWrapper = styled(Link)`
  padding: 1rem;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const LogoSign = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 1em;
    height: 1em;
    font-size: 2.5625rem;
  }

  @media ${media.phone} {
    svg {
      font-size: 4rem;
    }
  }

  @media ${media.tablet} {
    svg {
      font-size: 5rem;
    }
  }
`

export const LogoTitle = styled.span`
  display: none;

  @media ${media.phone} {
    display: flex;
    margin-top: 0.875rem;

    svg {
      width: 1.9em;
      height: 1em;
      font-size: 1.625rem;
    }
  }

  @media ${media.tablet} {
    margin-top: 1.5rem;

    svg {
      font-size: 2.9375rem;
    }
  }
`

export const LogoSubtitle = styled.span`
  display: none;
`
