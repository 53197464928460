import React from 'react'

import AdminTokenContext, { AdminTokenContextT } from './context'

type AdminTokenProviderT = {
  children: React.ReactNode
  token?: AdminTokenContextT
}

const AdminTokenProvider = ({ children, token }: AdminTokenProviderT) => {
  return (
    <AdminTokenContext.Provider value={token}>
      {children}
    </AdminTokenContext.Provider>
  )
}

export default AdminTokenProvider
