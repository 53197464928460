import React from 'react'

import { MessageWrapper } from './styles'

import Link from 'components/Link'
import { TextHeadline } from 'components/Typography/TextHeadline'
import Container from 'components/Container'
import { navigationItems } from 'components/layout/const'
import CTAButton from 'components/CTAButton'

const Message: React.FC = ({ children }) => (
  <MessageWrapper mt="4rem" mb="4rem">
    <TextHeadline>{children}</TextHeadline>
    <Container mt="3rem">
      <Link to={navigationItems[0].path[0]}>
        <CTAButton>wróć do repertuaru</CTAButton>
      </Link>
    </Container>
  </MessageWrapper>
)

export default Message
