import { useParams } from 'react-router-dom'
import { useOrderDetailsQuery } from 'setup/api/order/hooks'

export const useSummary = () => {
  const { orderToken } = useParams<{ orderToken: string }>()

  const { data, loading, error } = useOrderDetailsQuery({
    variables: {
      token: orderToken,
    },
  })

  return {
    orderDetails: data?.order,
    loading: loading,
    error: error,
  }
}
