import { ROMAN_DIGITS } from 'components/SeatsPicker/const'
import { RetrieveSession_retrieveSession_lockedSeats_nodes_seat } from 'setup/api/session/types/RetrieveSession'

export const getTicketSeatLabel = (
  seat: RetrieveSession_retrieveSession_lockedSeats_nodes_seat | null,
  columnsCount: number = 1,
) => {
  if (!seat) {
    return 'Unknown'
  }

  const column = Math.floor(seat.order / columnsCount)

  return `Rząd ${ROMAN_DIGITS[column]}, Miejsce ${seat.label}`
}
