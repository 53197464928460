import React, { useEffect } from 'react'

import TicketDropdown from 'components/TicketDropdown'
import CTAButton from 'components/CTAButton'
import TicketsLoader from 'components/TicketsNumbered/TicketsLoader'
import { useTicketsUtils } from './hooks'
import Container from 'components/Container'
import TotalPrice from 'components/TotalPrice'
import { TextBody } from 'components/Typography/TextBody'
import Action from 'components/Action'
import { useSeatsPickerData } from 'components/SeatsPicker/hooks'
import { useSessionContext } from 'providers/SessionProvider/hooks'

import {
  TicketsWrapper,
  TicketsRow,
  TicketsFields,
  TicketsBottom,
} from './styles'
import { getTicketSeatLabel } from './utils'
import LinkButton from 'components/LinkButton'

type TicketProps = {
  withoutSeats?: boolean
  showAddButton?: boolean
}

const Tickets = ({ showAddButton, withoutSeats }: TicketProps) => {
  const { data, isLoading: sessionLoading } = useSessionContext()
  const { changeTicket, isLoading, goToOrder } = useTicketsUtils()
  const { clearLockedSeat, createEmptyTicket } = useSeatsPickerData()

  // Create first ticket on mount
  useEffect(() => {
    if (
      withoutSeats &&
      !isLoading &&
      !!data?.lockedSeats &&
      data.lockedSeats.nodes?.length === 0
    ) {
      createEmptyTicket()
    }
  }, [createEmptyTicket, data, isLoading, withoutSeats])

  if (sessionLoading) {
    return <TicketsLoader />
  }

  const seatsList = data?.lockedSeats?.nodes || []
  const ticketPool =
    data?.event?.adminTicketPools?.nodes ||
    data?.event?.ticketPools?.nodes ||
    []

  if (!withoutSeats && !seatsList?.length) {
    return null
  }

  return (
    <TicketsWrapper>
      {seatsList.length > 0 && (
        <TicketsFields>
          {seatsList.map((item, index) => (
            <TicketsRow key={item.id}>
              {!withoutSeats && (
                <Container mb="0.75rem">
                  <TextBody>
                    {getTicketSeatLabel(item.seat, data?.event?.columnsNumber)}
                  </TextBody>
                </Container>
              )}
              {ticketPool?.length > 0 && (
                <TicketDropdown
                  disabled={isLoading}
                  lockedSeatId={item.id}
                  ticketId={item.ticket?.id}
                  group={ticketPool}
                  onChange={changeTicket}
                  onRemove={
                    (withoutSeats && seatsList.length > 1) || !withoutSeats
                      ? clearLockedSeat
                      : undefined
                  }
                  value={item.ticket ? `${item.ticket.ticketPoolId}` : '-1'}
                />
              )}
            </TicketsRow>
          ))}
        </TicketsFields>
      )}
      {!!showAddButton && (
        <Container mt={['0.5rem', '', '0']}>
          {seatsList.length > 0 ? (
            <LinkButton onClick={createEmptyTicket}>dodaj bilet</LinkButton>
          ) : (
            <Container mt={'29px'} />
          )}
        </Container>
      )}
      <TicketsBottom>
        <TotalPrice total={data?.amountGross || 0} />
        <Action>
          <CTAButton
            disabled={
              isLoading ||
              !seatsList.length ||
              seatsList.some((item) => !item.ticket)
            }
            onClick={goToOrder}
          >
            Kup Bilet
          </CTAButton>
        </Action>
      </TicketsBottom>
    </TicketsWrapper>
  )
}

export default Tickets
