import React, { useMemo, useState, ChangeEvent } from 'react'
import { UseFormMethods } from 'react-hook-form'

import {
  InputField,
  Label,
  InputWrapper,
  InputControl,
  InputFieldMask,
  ErrorMessage,
} from './styles'

interface IInput {
  name: string
  label: React.ReactNode
  type?: string
  mask?: string
  register: UseFormMethods['register']
  error?: { message: string }
  className?: string
  isRequired?: boolean
  validate?: (data: any) => string | undefined
  as?: React.ReactNode
}

const Input = ({
  className,
  name,
  register,
  error,
  label,
  validate,
  isRequired = true,
  type,
  mask,
}: IInput) => {
  const [inputClass, setInputClass] = useState('')

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== '') {
      setInputClass('active')
    } else {
      setInputClass('')
    }
  }

  const inputProps = useMemo(
    () => ({
      name,
      id: name,
      type,
      className: 'full-w',
      onChange: onInputChange,
    }),
    [name, type],
  )

  return (
    <InputWrapper className={className} isInvalid={!!error}>
      <InputControl className={inputClass}>
        {mask ? (
          <InputFieldMask
            mask={mask}
            inputRef={register({
              required: isRequired ? 'Pole jest wymagane' : false,
              validate,
            })}
            {...inputProps}
          />
        ) : (
          <InputField
            {...inputProps}
            ref={register({
              required: isRequired ? 'Pole jest wymagane' : false,
              validate,
            })}
          />
        )}
        <Label htmlFor={name}>{label}</Label>
      </InputControl>
      {!!error && <ErrorMessage>{error.message}</ErrorMessage>}
    </InputWrapper>
  )
}

export default Input
