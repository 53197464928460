export type ISeatType = 'isBooked' | 'isTaken' | 'free' | 'selected'

export const seatsLegend: {
  key: ISeatType
  value: string
  protectedType?: boolean
}[] = [
  { key: 'isBooked', value: 'rezerwacja', protectedType: true },
  { key: 'isTaken', value: 'zajęte' },
  { key: 'free', value: 'wolne' },
  { key: 'selected', value: 'wybrane' },
]
