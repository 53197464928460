import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { useLocation, matchPath, useParams, generatePath } from 'react-router'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { SwiperOptions, Navigation } from 'swiper'
import SwiperClass from 'swiper/types/swiper-class'
import 'swiper/swiper.scss'

import { media } from 'common/MediaQueries'
import Link from 'components/Link'
import { ReactComponent as ArrowLeft } from 'assets/arrow-left.svg'
import { ReactComponent as ArrowRight } from 'assets/arrow-right.svg'
import {
  HeaderNavigationWrapper,
  NavigationList,
  NavigationItem,
  NavigationText,
} from './styles'
import HeaderBase from './HeaderBase'
import { navigationItems, NAVIGATION_KEYS } from './const'
import { EventParams } from 'setup/types'
import { useAdminToken } from 'providers/AdminTokenProvider/hooks'

SwiperCore.use([Navigation])

const params: SwiperOptions = {
  slidesPerView: 1,
}

const Header = () => {
  const { pathname } = useLocation()
  const { eventId } = useParams<EventParams>()
  const sliderRef = useRef<SwiperClass | null>(null)
  const mediaQueryListener = useMemo(() => matchMedia(`${media.tablet}`), [])
  const [isDesktop, setIsDesktop] = useState(mediaQueryListener.matches)
  const adminToken = useAdminToken()

  const activeRouteIndex = navigationItems.findIndex((item) =>
    item.path.some((path) => matchPath(pathname, { exact: true, path })),
  )

  const isSummaryPage =
    navigationItems[activeRouteIndex].key === NAVIGATION_KEYS.Summary

  const updateSlider = useCallback(() => {
    setIsDesktop(!!mediaQueryListener.matches)
  }, [mediaQueryListener.matches])

  useEffect(() => {
    window.addEventListener('resize', updateSlider)

    return () => {
      window.removeEventListener('resize', updateSlider)
    }
  }, [updateSlider])

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.update()
      sliderRef.current.slideTo(activeRouteIndex)
    }
  }, [activeRouteIndex])

  const setSliderRef = useCallback((swiper) => {
    sliderRef.current = swiper
  }, [])

  if (!!adminToken) {
    return (
      <HeaderBase>
        <HeaderNavigationWrapper />
      </HeaderBase>
    )
  }

  if (isSummaryPage) {
    return (
      <HeaderBase>
        <HeaderNavigationWrapper>
          <NavigationList>
            <NavigationItem isActive={true} isDone={false}>
              <NavigationText>
                {navigationItems[activeRouteIndex].text}
              </NavigationText>
            </NavigationItem>
          </NavigationList>
        </HeaderNavigationWrapper>
      </HeaderBase>
    )
  }

  return (
    <HeaderBase>
      <HeaderNavigationWrapper>
        <NavigationList>
          {isDesktop ? (
            navigationItems.map((item, index) => (
              <NavigationItem
                isActive={activeRouteIndex === index}
                isDone={activeRouteIndex > index}
                key={item.path[0]}
              >
                {activeRouteIndex > index ? (
                  <Link to={generatePath(item.path[0], { eventId })}>
                    <NavigationText>{item.text}</NavigationText>
                  </Link>
                ) : (
                  <NavigationText>{item.text}</NavigationText>
                )}
              </NavigationItem>
            ))
          ) : (
            <>
              <Swiper
                {...params}
                onSwiper={setSliderRef}
                navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }}
              >
                {navigationItems.map((item, index) => (
                  <SwiperSlide key={item.path[0]}>
                    <NavigationItem
                      isActive={activeRouteIndex === index}
                      isDone={activeRouteIndex > index}
                    >
                      {activeRouteIndex > index ? (
                        <Link to={generatePath(item.path[0], { eventId })}>
                          <NavigationText>{item.text}</NavigationText>
                        </Link>
                      ) : (
                        <NavigationText>{item.text}</NavigationText>
                      )}
                    </NavigationItem>
                  </SwiperSlide>
                ))}
              </Swiper>
              <span className="swiper-button-next">
                <ArrowRight />
              </span>
              <span className="swiper-button-prev">
                <ArrowLeft />
              </span>
            </>
          )}
        </NavigationList>
      </HeaderNavigationWrapper>
    </HeaderBase>
  )
}

export default Header
