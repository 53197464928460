import React from 'react'
import { UseFormMethods } from 'react-hook-form'

import { Label, Wrapper, RadioInput, StyledRadio } from './styles'

interface IRadio {
  name: string
  value: string
  label: React.ReactNode
  className?: string
  register: UseFormMethods['register']
  error?: { message: string }
  isRequired?: boolean
  defaultChecked?: boolean
  onChange?: (value: any) => void
}

const Radio = ({
  name,
  label,
  register,
  error,
  defaultChecked,
  className,
  onChange,
  value,
}: IRadio) => {
  return (
    <Wrapper htmlFor={value} hasError={!!error} className={className}>
      <Label>{label}</Label>
      <RadioInput
        id={value}
        onChange={onChange}
        defaultChecked={defaultChecked}
        type="radio"
        name={name}
        ref={register}
        value={value}
      />
      <StyledRadio />
    </Wrapper>
  )
}

export default Radio
