import React, { useMemo } from 'react'
import parse from 'date-fns/parse'

import { firstToUpper } from 'utils/string'
import Container from 'components/Container'
import EventsList from 'components/EventsList'
import { EventList_events_nodes } from 'setup/api/events/types/EventList'
import { TextHeadline } from 'components/Typography/TextHeadline'

import ListLoader from './ListLoader'
import Empty from './Empty'
import { ScheduleWrapper, LoaderWrapper } from './styles'
import { API_DATE_FORMAT } from 'setup/consts'
import { formatToLongDisplayDate } from 'utils/date'

interface ISchedule {
  day: string
  loading?: boolean
  events?: EventList_events_nodes[]
}

const Schedule = ({ events, loading, day }: ISchedule) => {
  const formattedTitle = useMemo(
    () => formatToLongDisplayDate(parse(day, API_DATE_FORMAT, new Date())),
    [day],
  )
  const hasAnyEvents = events && events.length

  return (
    <ScheduleWrapper>
      <Container
        pl={['2rem', '']}
        pr={['2rem', '']}
        mt={['2rem', '2rem', '4rem']}
      >
        <TextHeadline>
          <Container as="span" display={['none', '', 'inline']}>
            Repertuar:{' '}
          </Container>
          {firstToUpper(formattedTitle)}
        </TextHeadline>
      </Container>
      <Container position="relative">
        {loading && !events && (
          <LoaderWrapper>
            <ListLoader />
          </LoaderWrapper>
        )}
        {!loading && !hasAnyEvents && <Empty />}
        {!loading && hasAnyEvents && <EventsList data={events!} />}
      </Container>
    </ScheduleWrapper>
  )
}

export default Schedule
