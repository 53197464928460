import React from 'react'
import { SvgComponent } from 'definitions/Svg'

const Close: SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.414"
    height="17.414"
    {...props}
  >
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M.707 16.707l4.333-4.333 3.667-3.667-8-8" />
      <path d="M16.707.707L12.374 5.04 8.707 8.707l8 8" />
    </g>
  </svg>
)

export default Close
