import React, { useEffect } from 'react'
import { LayoutWrapper, BodyWrapper } from './styles'
import Header from './Header'
import Footer from './Footer'
import { useLocation } from 'react-router'
import Close from './Close'

const Layout: React.FC = ({ children }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <LayoutWrapper>
      <Close />
      <Header />
      <BodyWrapper>{children}</BodyWrapper>
      <Footer />
    </LayoutWrapper>
  )
}

export default Layout
