import styled, { css } from 'styled-components'
import { media } from 'common/MediaQueries'

import { COLORS } from 'setup/theme'
import { transition } from 'common/mixins'

export interface ISeatBlock {
  isDisabled?: boolean
  isTaken?: boolean
  isBooked?: boolean
  isSelected?: boolean
  className?: string
}

export const SeatBlock = styled.button<ISeatBlock>`
  border: 1px solid ${COLORS.white};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.75rem;
  width: 1.75rem;
  font-size: 0.75rem;
  color: ${COLORS.white};
  background-color: transparent;
  outline: 0;
  flex-shrink: 0;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin-left: 0.5rem;
  cursor: pointer;
  ${transition}

  :focus {
    outline: 0;
  }


  ${({ isTaken }) =>
    !isTaken &&
    css`
      :hover {
        background-color: ${COLORS.white};
        color: ${COLORS.black};
      }
    `}


  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${COLORS.white};
      color: ${COLORS.black};
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: default;
      pointer-events: none;
    `}

  ${({ isTaken }) =>
    isTaken &&
    css`
      cursor: not-allowed;
      color: ${COLORS.grey};
      border: 1px solid ${COLORS.grey};
      cursor: default;
    `}

  ${({ isBooked, isTaken }) =>
    isBooked &&
    !isTaken &&
    css`
      color: ${COLORS.yellow};
      border: 1px solid ${COLORS.yellow};
    `}

  @media ${media.tablet} {
    height: 2rem;
    width: 2rem;
  }
`

export const Space = styled.div`
  display: block;
  height: 1.75rem;
  width: 1.75rem;
  flex-shrink: 0;
  margin-left: 0.5rem;

  @media ${media.tablet} {
    height: 2rem;
    width: 2rem;
  }
`

export const RowNumber = styled.span`
  display: flex;
  height: 1.875rem;
  width: 1.5rem;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${COLORS.grey2};
  font-size: 0.625rem;

  &:first-child {
    margin-right: 0.5rem;
  }

  &:last-child {
    margin-left: 1rem;
  }

  @media ${media.phone} {
    width: 1.875rem;
    font-size: 0.8125rem;
  }

  @media ${media.tablet} {
    font-size: 1rem;
  }

  @media ${media.desktop} {
    &:first-child {
      margin-right: 1rem;
    }
  }
`
