import addMinutes from 'date-fns/addMinutes'

export const firstToUpper = (text: string) => {
  return `${text[0].toUpperCase()}${text.slice(1)}`
}

export const parseAmount = (amount: number) => (amount / 1000).toFixed(2)

export type EventCalendarType = {
  show?: {
    name: string
    descLong: string | null
    descShort: string | null
    duration: number
  } | null
  date: string
}

export const generateGoogleCalendarLink = (event: EventCalendarType) => {
  const name = event.show?.name || 'Wydarzenie'
  const details = event.show?.descLong || event.show?.descShort || 'Brak opisu'
  const eventDate = event!.date
  const dateStart = eventDate.replace(/[-:.]/g, '')
  const dateEnd = addMinutes(new Date(eventDate), event.show?.duration || 60)
    .toISOString()
    .replace(/[-:.]/g, '')

  return `https://www.google.com/calendar/render?action=TEMPLATE&dates=${dateStart}/${dateEnd}&text=${name}&details=${details}`
}
