import styled from 'styled-components'
import { media, customMedia } from 'common/MediaQueries'

export const SeatsWrapper = styled.div`
  position: relative;
  margin-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  /* Height of the legend box  */
  padding-bottom: 150px;

  @media ${media.tablet} {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @media ${media.desktop} {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`

export const SeatsCol = styled.div`
  display: flex;
  flex-direction: column;

  @media ${media.tablet} {
    margin-top: 5.875rem;
    flex-shrink: 0;
    width: 15rem;
  }

  @media ${customMedia.scene} {
    width: 18rem;
  }

  @media ${media.desktop} {
    width: 20.5rem;
  }
`

export const SeatsInfo = styled(SeatsCol)`
  @media ${customMedia.scene} {
    width: 16rem;
  }

  @media ${media.desktop} {
    width: 20.5rem;
  }
`

export const SeatsTickets = styled(SeatsCol)`
  position: relative;
  display: flex;
  margin-top: 4rem;
`

export const SeatsContent = styled.div`
  display: flex;
  flex-direction: column;

  @media ${media.tablet} {
    align-items: center;
    max-width: calc(100% - (15rem * 2) - (1rem * 2));
    margin: 0 1rem;
  }

  @media ${customMedia.scene} {
    max-width: calc(100% - 16rem - 18rem - (1rem * 2));
  }

  @media ${media.desktop} {
    max-width: calc(100% - (20.5rem * 2) - (2rem * 2));
    margin: 0 2rem;
  }
`
