export const COLORS = {
  black: '#2b3033',
  white: '#ebebeb',
  red: '#ff0000',
  blue: '#1984ff',
  blueHover: '#0067dd',
  lightGrey: '#e4e4e4',
  lightGrey2: '#f2f2f2',
  grey: '#525252',
  grey2: '#a8a8a8',
  grey3: '#5a5a5a', //border lines
  yellow: '#ffeb3b',
}

export const SIZES = {
  header: '200px',
  headerTablet: '112px',
  headerMobile: '72px',
  headerDesktop: '190px',
  footer: '200px',
}

const theme = {
  breakpoints: ['768px', '1025px', '1600px'],
  fontFamilies: {
    archia: `proxima-nova, sans-serif`,
    nooks: `'TT Nooks Script', Helvetica, sans-serif;`,
  },
  sizes: {
    inner: '1200px',
    scene: '1260px',
  },
  fontWeights: {
    thin: 100,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    heavy: 900,
  },
}

export default theme
