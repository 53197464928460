import styled from 'styled-components'

import { COLORS } from 'setup/theme'

export const TextBody = styled.p`
  color: ${COLORS.white};
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`
