import { gql } from '@apollo/client'

import { SESSION_FRAGMENT } from '../session/fragments'

export const CREATE_LOCKED_SEAT = gql`
  ${SESSION_FRAGMENT}
  mutation CreateLockedSeat(
    $token: String!
    $input: LockedSeatCreateInput!
    $isAdmin: Boolean!
  ) {
    createLockedSeat(sessionToken: $token, input: $input) {
      ...Session
    }
  }
`

export const UPDATE_LOCKED_SEAT = gql`
  ${SESSION_FRAGMENT}
  mutation UpdateLockedSeat(
    $id: Int!
    $token: String!
    $input: LockedSeatUpdateInput!
    $isAdmin: Boolean!
  ) {
    updateLockedSeat(id: $id, sessionToken: $token, input: $input) {
      ...Session
    }
  }
`

export const DELETE_LOCKED_SEAT = gql`
  ${SESSION_FRAGMENT}
  mutation DeleteLockedSeat($id: Int!, $token: String!, $isAdmin: Boolean!) {
    deleteLockedSeat(id: $id, sessionToken: $token) {
      ...Session
    }
  }
`
