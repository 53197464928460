import styled from 'styled-components'

const Inner = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: ${({ theme }) => theme.sizes.inner};
`

export default Inner
