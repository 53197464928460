import { gql } from '@apollo/client'

export const CREATE_ORDER = gql`
  mutation CreateOrder($token: String!, $input: OrderCreateInput!) {
    createOrder(sessionToken: $token, input: $input) {
      id
      paymentUrl
      status
    }
  }
`
