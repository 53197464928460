import { gql } from '@apollo/client'

export const EVENT_FRAGMENT = gql`
  fragment Event on Event {
    id
    date
    showId
    location
    seatType
    ticketLimit
    rowsNumber
    columnsNumber
    status
  }
`
