import { gql } from '@apollo/client'

export const TICKET_POOL_FRAGMENT = gql`
  fragment TicketPool on TicketPool {
    id
    name
    priceGross
    priceTaxRate
    isOnline
    ticketLimit
    status
    ticketLeft
  }
`
