import { gql } from '@apollo/client'
import { EVENT_FRAGMENT } from './fragments'

export const EVENT_LIST = gql`
  ${EVENT_FRAGMENT}
  query EventList(
    $take: Int
    $skip: Int
    $filter: EventFilterInput
    $orderBy: [EventOrderByInput!]
  ) {
    events(take: $take, skip: $skip, filter: $filter, orderBy: $orderBy) {
      nodes {
        ...Event
        show {
          id
          name
          descShort
        }
      }
      count
    }
  }
`
