import ExternalLink from 'components/ExternalLink'
import React from 'react'
import { TransactionProvider } from 'setup/api/types/globalTypes'
import { TERMS_URL } from 'setup/consts'

// eslint-disable-next-line
export const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const phoneRegex = /^\+?[0-9\s]{9,20}$/
export const nipRegex = /^[0-9]{10}$/

interface IOption {
  value: string
  label: string
}

export interface IInput {
  name: string
  label: React.ReactNode
  isRequired?: boolean
  small?: boolean
  full?: boolean
  type?: string
  mask?: string
  validate?: (data: any) => string | undefined
  fieldType?: 'input' | 'textarea'
  authorizedOnly?: boolean
}

export const INPUTS: IInput[] = [
  { name: 'fname', label: 'Imię' },
  { name: 'lname', label: 'Nazwisko' },
  {
    name: 'email',
    label: 'Email',
    validate: (data: string) => {
      return !data || emailRegex.test(data)
        ? undefined
        : 'Email jest niepoprawny'
    },
  },
  {
    name: 'email2',
    label: 'Powtórz Email',
  },
  {
    name: 'phone',
    label: 'Telefon',
    validate: (data: string) => {
      return !data || phoneRegex.test(data)
        ? undefined
        : 'Telefon jest niepoprawny'
    },
  },
]

export const GUEST_CHECKBOXES: IInput[] = [
  {
    name: 'termsAgreement',
    label: (
      <>
        Akceptuję postanowienia{' '}
        <ExternalLink
          href={TERMS_URL()}
          target="_blank"
          rel="noopener noreferrer"
        >
          regulaminu sprzedaży
        </ExternalLink>
      </>
    ),
    isRequired: true,
  },
]

export const CHECKBOXES: IInput[] = [
  {
    name: 'privacyAgreement',
    label:
      'Wyrażam zgodę na przetwarzanie moich, wskazanych powyżej danych osobowych przez Nie Teatr w celach marketingowych i reklamowych',
  },
  {
    name: 'marketingAgreement',
    label:
      'Wyrażam zgodę na otrzymywanie od Nie Teatr informacji handlowych drogą elektroniczną na wskazany powyżej numer telefonu i adres email',
  },
]

const INVOICE_SHARED_FIELDS: IInput[] = [
  {
    name: 'city',
    label: 'Miasto',
  },
  {
    name: 'zip',
    label: 'Kod',
    small: true,
    mask: '99-999',
  },
  {
    name: 'street',
    label: 'Ulica',
  },
  {
    name: 'streetNumber',
    label: 'Nr',
    small: true,
  },
  {
    name: 'apartment',
    label: 'lok.',
    small: true,
    isRequired: false,
  },
  {
    fieldType: 'textarea',
    name: 'invoiceNote',
    label: 'Uwagi do faktury',
    full: true,
    authorizedOnly: true,
    isRequired: false,
  },
]

export const INVOICE_INPUTS: IInput[] = [
  {
    name: 'companyName',
    label: 'Nazwa firmy',
    isRequired: true,
  },
  {
    name: 'nip',
    label: 'NIP',
    isRequired: true,
    type: 'number',
    validate: (data: string) => {
      return nipRegex.test(data) ? undefined : 'Nip jest niepoprawny'
    },
  },
  ...INVOICE_SHARED_FIELDS,
]

export const INVOICE_USER_INPUTS: IInput[] = [
  {
    name: 'firstName',
    label: 'Imię',
  },
  {
    name: 'lastName',
    label: 'Nazwisko',
  },
  ...INVOICE_SHARED_FIELDS,
]

export const PROVIDERS_OPTIONS: IOption[] = [
  {
    label: 'Karta',
    value: TransactionProvider.CARD,
  },
  {
    label: 'Gotówka',
    value: TransactionProvider.CASH,
  },
  {
    label: 'Przelew',
    value: TransactionProvider.TRANSFER,
  },
  {
    label: 'Voucher',
    value: TransactionProvider.VOUCHER,
  },
]
