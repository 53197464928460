import React from 'react'

import format from 'date-fns/format'

import CTAButton from 'components/CTAButton'
import { ButtonType, ButtonSize } from 'components/CTAButton/consts'
import Container from 'components/Container'
import { TextBody } from 'components/Typography/TextBody'
import { TextCaption } from 'components/Typography/TextCaption'
import { TextHeadline } from 'components/Typography/TextHeadline'
import Link from 'components/Link'

import {
  ListWrapper,
  EventDetails,
  Hour,
  Room,
  Action,
  Content,
  EventBody,
  Details,
} from './styles'
import { useEventsApi } from './hooks'
import { EventList_events_nodes } from 'setup/api/events/types/EventList'
import { isMoreThanMinuteAgo } from 'utils/date'

interface IEventsList {
  data: EventList_events_nodes[]
}

const EventsList = ({ data }: IEventsList) => {
  const { items, checkDate } = useEventsApi(data)

  return (
    <ListWrapper>
      {items.map((item) => (
        <EventDetails key={item.id}>
          <EventBody>
            <Hour className={'c-repertoire__hour'}>
              <TextBody>{format(new Date(item.date), 'HH:mm')}</TextBody>
            </Hour>
            <Content>
              <Container maxWidth="27.5rem">
                <TextHeadline as="span">{item.show?.name}</TextHeadline>
              </Container>
              {item.show && (
                <Details mt={'0.375rem'}>{item.show?.descShort}</Details>
              )}
            </Content>
            <Room>
              <TextCaption>{item.location}</TextCaption>
            </Room>
            <Action>
              {!isMoreThanMinuteAgo(new Date(item.date)) && (
                <Link to={item.detailsUrl} onClick={checkDate(item)}>
                  <CTAButton
                    btnType={ButtonType.default}
                    btnSize={ButtonSize.small}
                  >
                    Kup Bilety
                  </CTAButton>
                </Link>
              )}
            </Action>
          </EventBody>
        </EventDetails>
      ))}
    </ListWrapper>
  )
}

export default EventsList
