import styled from 'styled-components'

import { COLORS } from 'setup/theme'

export const TextSmall = styled.p`
  color: ${COLORS.white};
  font-size: 0.75rem;
  line-height: 1.5;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`
