import React from 'react'
import { SvgComponent } from 'definitions/Svg'

const ArrowDown: SvgComponent = (props) => (
  <svg width="8.45" height="5.562" {...props}>
    <path
      d="M.699.715l1.91 1.867 1.616 1.581L7.75.715"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
)

export default ArrowDown
