import styled from 'styled-components'
import InputMask from 'react-input-mask'
import { COLORS } from 'setup/theme'

import { fieldReset, transition } from 'common/mixins'
import { media } from 'common/MediaQueries'

export const Label = styled.label`
  position: absolute;
  top: 50%;
  left: 1.5rem;
  transform: translateY(-50%);
  color: ${COLORS.grey};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: 1.25rem;
  ${transition}
`

export const InputField = styled.input`
  ${fieldReset}
  min-height: 4rem;
  padding: 1rem 1.5rem 0.5rem 1.5rem;
  font-family: ${({ theme }) => theme.fontFamilies.archia};
  color: ${COLORS.lightGrey};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  border: 1px solid ${COLORS.lightGrey};

  &:focus {
    border-color: ${COLORS.blue};
  }

  &:focus + ${Label} {
    font-size: 0.75rem;
    top: 25%;
  }
`

export const InputFieldMask = styled(InputMask)`
  ${fieldReset}
  min-height: 4rem;
  padding: 1rem 1.5rem 0.5rem 1.5rem;
  font-family: ${({ theme }) => theme.fontFamilies.archia};
  color: ${COLORS.lightGrey};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  border: 1px solid ${COLORS.lightGrey};

  &:focus {
    border-color: ${COLORS.blue};
  }

  &:focus + ${Label} {
    font-size: 0.75rem;
    top: 25%;
  }
`

export const InputControl = styled.div`
  position: relative;

  &.active {
    ${Label} {
      font-size: 0.75rem;
      top: 25%;
    }
  }
`

export const InputWrapper = styled.div<{ isInvalid: boolean }>`
  position: relative;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 1rem;
  ${({ isInvalid }) =>
    isInvalid &&
    `
    ${InputField},
    .active ${InputField} {
      border-color: ${COLORS.blue};
    }
  `};

  @media ${media.phone} {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 2rem;
  }
`

export const ErrorMessage = styled.p`
  color: ${COLORS.red};
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin-top: 0.5rem;
  font-size: 0.75rem;

  @media ${media.phone} {
    font-size: 0.625rem;
  }
`
