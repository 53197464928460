import { gql } from '@apollo/client'

export const EVENT_SEAT_FRAGMENT = gql`
  fragment EventSeat on EventSeat {
    id
    eventId
    label
    order
    status
    type
  }
`
