import { makeMutationHook } from 'setup/api/apollo-utils'

import { CREATE_TICKET, UPDATE_TICKET } from './mutations'
import { CreateTicket, CreateTicketVariables } from './types/CreateTicket'
import { UpdateTicket, UpdateTicketVariables } from './types/UpdateTicket'

export const useCreateTicketMutation = makeMutationHook<
  CreateTicket,
  CreateTicketVariables
>(CREATE_TICKET)

export const useUpdateTicketMutation = makeMutationHook<
  UpdateTicket,
  UpdateTicketVariables
>(UPDATE_TICKET)
