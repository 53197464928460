import React from 'react'

import { Link } from './styles'

const ExternalLink: React.FC<React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  children,
  ...props
}) => {
  return <Link {...props}>{children}</Link>
}

export default ExternalLink
