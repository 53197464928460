import React from 'react'
import { FooterWrapper } from './styles'
import { TextCaption } from 'components/Typography/TextCaption'

const Footer = () => {
  return (
    <FooterWrapper>
      <TextCaption>Powered by Ticket Funnel</TextCaption>
    </FooterWrapper>
  )
}

export default Footer
