import { generatePath } from 'react-router'
import { PATH_PREFIX } from 'setup/consts'

export const ROUTES = {
  EVENTS: '/events',
  EVENTS_SELECTED: '/events/:date',
  EVENT_DETAILS: '/event/:eventId',
  FORM: '/form/:eventId',
  SUMMARY: '/summary/:orderToken',
}

export const getPaymentReturnUrl = () => {
  const prefix = `${window.location.origin}${window.location.pathname}${window.location.search}`
  // It's encoding the `{` & `}` characters so we need to decode that
  const suffix = decodeURIComponent(
    generatePath(ROUTES.SUMMARY, { orderToken: '{orderToken}' }),
  )

  return `${prefix}#/${PATH_PREFIX}${suffix}`
}
