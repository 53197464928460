import { gql } from '@apollo/client'

export const ORDER_FRAGMENT = gql`
  fragment Order on Order {
    id
    token
    amountGross
    status
    paymentUrl
  }
`
