import React, { useMemo, useState, useCallback, useEffect } from 'react'
import ContentLoader from 'react-content-loader'
import { COLORS } from 'setup/theme'
import { media } from 'common/MediaQueries'

const SingleRow: React.FC = () => {
  const mediaQueryListenerPhone = useMemo(
    () => matchMedia(`${media.phone} and (max-width: 1024px)`),
    [],
  )
  const mediaQueryListenerTablet = useMemo(
    () => matchMedia(`${media.tablet}`),
    [],
  )

  const [abovePhone, setAbovePhone] = useState(mediaQueryListenerPhone.matches)
  const [aboveTablet, setAboveTablet] = useState(
    mediaQueryListenerTablet.matches,
  )

  const updateViewport = useCallback(() => {
    setAbovePhone(!!mediaQueryListenerPhone.matches)
    setAboveTablet(!!mediaQueryListenerTablet.matches)
  }, [mediaQueryListenerPhone.matches, mediaQueryListenerTablet.matches])

  useEffect(() => {
    window.addEventListener('resize', updateViewport)

    return () => {
      window.removeEventListener('resize', updateViewport)
    }
  }, [updateViewport])

  const renderLoader = () => {
    if (abovePhone && !aboveTablet) {
      return (
        <ContentLoader
          viewBox="0 0 1000 160"
          backgroundColor={COLORS.grey}
          foregroundColor={COLORS.white}
        >
          <rect x="20" y="20" rx="4" ry="4" width="70" height="20" />
          <rect x="20" y="50" rx="3" ry="3" width="240" height="20" />
          <rect x="20" y="90" rx="3" ry="3" width="180" height="14" />
          <rect x="650" y="30" rx="3" ry="3" width="80" height="14" />
          <rect x="800" y="20" width="100" height="40" />
        </ContentLoader>
      )
    } else if (aboveTablet) {
      return (
        <ContentLoader
          viewBox="0 0 1000 100"
          backgroundColor={COLORS.grey}
          foregroundColor={COLORS.white}
        >
          <rect x="20" y="40" rx="4" ry="4" width="70" height="20" />
          <rect x="140" y="40" rx="3" ry="3" width="240" height="20" />
          <rect x="140" y="70" rx="3" ry="3" width="180" height="14" />
          <rect x="700" y="60" rx="3" ry="3" width="80" height="14" />
          <rect x="850" y="50" width="100" height="40" />
        </ContentLoader>
      )
    } else {
      return (
        <ContentLoader
          viewBox="0 0 1000 400"
          backgroundColor={COLORS.grey}
          foregroundColor={COLORS.white}
        >
          <rect x="80" y="20" rx="4" ry="4" width="100" height="30" />
          <rect x="80" y="70" rx="3" ry="3" width="380" height="30" />
          <rect x="80" y="120" rx="3" ry="3" width="380" height="20" />
          <rect x="80" y="180" rx="3" ry="3" width="120" height="20" />
          <rect x="80" y="240" width="260" height="60" />
        </ContentLoader>
      )
    }
  }

  return renderLoader()
}

const ListLoader: React.FC = () => {
  return (
    <>
      <SingleRow />
      <SingleRow />
    </>
  )
}

export default ListLoader
