import { makeMutationHook, makeQueryHook } from 'setup/api/apollo-utils'

import { CREATE_ORDER } from './mutations'
import { ORDER_DETAILS } from './queries'
import { CreateOrder, CreateOrderVariables } from './types/CreateOrder'
import { OrderDetails, OrderDetailsVariables } from './types/OrderDetails'

export const useCreateOrderMutation = makeMutationHook<
  CreateOrder,
  CreateOrderVariables
>(CREATE_ORDER)

export const useOrderDetailsQuery = makeQueryHook<
  OrderDetails,
  OrderDetailsVariables
>(ORDER_DETAILS)
