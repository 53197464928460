import { createGlobalStyle } from 'styled-components'
// @ts-ignore
import reset from 'styled-reset-advanced'
import { COLORS } from 'setup/theme'

const GlobalStyles = createGlobalStyle`
  ${reset}

  html {
    font-size: 16px;
  }

  body {
    background-color: #000;
    color: #fff;
    line-height: 1.4;
    font-family: proxima-nova, sans-serif;
  }

  p, h1, h2, h3, h4, h5, h6, blockquote, pre, ul, ol, li {
    user-select: none;
  }

  iframe,
  img {
    display: block;
    max-width: 100%;
  }

  .Toastify__toast {
    padding: 1rem 1rem 1.125rem 1rem;
    min-height: auto;
    font-family: proxima-nova, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
  }

  .Toastify__close-button {
    display: flex;
    align-self: center;
    opacity: 1;

    &:hover {
      color: ${COLORS.black};
    }
  }

  .Toastify__toast--error {
    background: ${COLORS.red};
  }
`

export default GlobalStyles
