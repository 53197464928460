import React from 'react'

import { LegendList, LegendItem, LegendSeat } from './styles'
import { seatsLegend } from './consts'
import { useAdminToken } from 'providers/AdminTokenProvider/hooks'

const Legend = () => {
  const isLoggedIn = !!useAdminToken()

  return (
    <LegendList>
      {seatsLegend
        .filter(({ protectedType }) => (protectedType ? isLoggedIn : true))
        .map(({ key, value }) => (
          <LegendItem key={key}>
            <LegendSeat seatKind={key}>1</LegendSeat> {value}
          </LegendItem>
        ))}
    </LegendList>
  )
}
export default Legend
