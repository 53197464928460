import styled from 'styled-components'

import { COLORS } from 'setup/theme'
import { media } from 'common/MediaQueries'

export const ActionWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  &::before {
    content: '';
    position: absolute;
    top: -4.5rem;
    left: 0;
    right: 0;
    height: 4.5rem;
    background-image: linear-gradient(to top, ${COLORS.black}, transparent);
    pointer-events: none;
  }

  > button {
    width: 100%;
  }

  @media ${media.tablet} {
    position: static;

    &::before {
      display: none;
    }

    > button {
      width: auto;
    }
  }
`
