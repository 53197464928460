import React, { useEffect } from 'react'

import { Switch, Redirect, generatePath, useHistory } from 'react-router-dom'

import Events from 'pages/Events'
import EventDetails from 'pages/EventDetails'
import UserForm from 'pages/UserForm'
import Summary from 'pages/Summary'

import { ROUTES } from './consts'
import LayoutRoute from './LayoutRoute'

type RoutesProps = {
  eventId?: string
}

const Routes = ({ eventId }: RoutesProps) => {
  const history = useHistory()

  useEffect(() => {
    if (eventId) {
      history.replace(generatePath(ROUTES.EVENT_DETAILS, { eventId }))
    }
  }, [eventId, history])

  return (
    <Switch>
      <LayoutRoute path={ROUTES.EVENTS} component={Events} exact />
      <LayoutRoute path={ROUTES.EVENTS_SELECTED} component={Events} exact />
      <LayoutRoute path={ROUTES.EVENT_DETAILS} component={EventDetails} exact />
      <LayoutRoute path={ROUTES.FORM} component={UserForm} exact />
      <LayoutRoute path={ROUTES.SUMMARY} component={Summary} exact />
      <Redirect to={ROUTES.EVENTS} />
    </Switch>
  )
}

export default Routes
