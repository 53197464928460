import { useCallback, useContext, useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { ApolloError } from '@apollo/client'

import { RetrieveSession_retrieveSession_lockedSeats_nodes } from 'setup/api/session/types/RetrieveSession'
import SessionContext from 'providers/SessionProvider/context'
import {
  useCreateLockedSeatMutation,
  useDeleteLockedSeatMutation,
} from 'setup/api/lockedSeats/hooks'
import { CreateLockedSeat } from 'setup/api/lockedSeats/types/CreateLockedSeat'
import { DeleteLockedSeat } from 'setup/api/lockedSeats/types/DeleteLockedSeat'
import { ERROR_MESSAGES } from 'setup/consts'
import { useAdminToken } from 'providers/AdminTokenProvider/hooks'
import ReactTooltip from 'react-tooltip'

export const useSeatsPickerData = () => {
  const { data, setData, retrieveSession, clearSession } = useContext(
    SessionContext,
  )
  const adminToken = useAdminToken()

  const onErrorCallback = useCallback(
    (error: ApolloError) => {
      let errorsShown = false
      error.graphQLErrors.forEach((error) => {
        if (ERROR_MESSAGES[error.extensions?.code]) {
          toast.error(ERROR_MESSAGES[error.extensions?.code])
          errorsShown = true
        }
      })
      if (!errorsShown) {
        toast.error(ERROR_MESSAGES.DEFAULT)

        if (data) {
          retrieveSession(`${data.eventId}`)
        }
      }
    },
    [data, retrieveSession],
  )

  const onSuccessCallback = useCallback(
    (data: CreateLockedSeat | DeleteLockedSeat) => {
      const sessionData =
        (data as DeleteLockedSeat).deleteLockedSeat ||
        (data as CreateLockedSeat).createLockedSeat

      if (sessionData) {
        setData(sessionData)
      }
    },
    [setData],
  )

  const mutationParams = useMemo(
    () => ({
      onCompleted: onSuccessCallback,
      onError: onErrorCallback,
    }),
    [onErrorCallback, onSuccessCallback],
  )

  const [createLockSeat, createLockSeatOptions] = useCreateLockedSeatMutation(
    mutationParams,
  )

  const [deleteLockSeat, deleteLockSeatOptions] = useDeleteLockedSeatMutation(
    mutationParams,
  )

  const lockedSeatsMap = useMemo(() => {
    if (data?.lockedSeats?.nodes?.length) {
      return data?.lockedSeats?.nodes.reduce(
        (result, seat) => ({
          ...result,
          [`${seat.seatId}`]: seat,
        }),
        {} as Record<string, RetrieveSession_retrieveSession_lockedSeats_nodes>,
      )
    }
    return {}
  }, [data])

  const seatsArray = useMemo(() => {
    if (
      !data ||
      !data.event ||
      !data.event.rowsNumber ||
      !data.event.columnsNumber ||
      !data.event.eventSeats?.nodes
    ) {
      return []
    }

    return new Array(data.event.rowsNumber).fill(null).map((_, rowIndex) =>
      new Array(data.event!.columnsNumber).fill(null).map((_, colIndex) => {
        const currentIndex = colIndex + rowIndex * data.event!.rowsNumber

        return data.event!.eventSeats!.nodes![currentIndex]
      }),
    )
  }, [data])

  const clearLockedSeat = useCallback(
    (lockedSeatId: number) => {
      if (data?.token) {
        deleteLockSeat({
          variables: {
            id: lockedSeatId,
            token: data.token,
            isAdmin: !!adminToken,
          },
        })
      }
    },
    [data, deleteLockSeat, adminToken],
  )

  const onSeatClick = useCallback(
    (seat) => {
      if (!data?.token) {
        clearSession()
        return
      }

      if (lockedSeatsMap[seat.id]) {
        deleteLockSeat({
          variables: {
            id: lockedSeatsMap[seat.id].id,
            token: data.token,
            isAdmin: !!adminToken,
          },
        })
      } else {
        createLockSeat({
          variables: {
            token: data.token,
            input: {
              seatId: seat.id,
            },
            isAdmin: !!adminToken,
          },
        })
      }
    },
    [
      adminToken,
      clearSession,
      createLockSeat,
      data,
      deleteLockSeat,
      lockedSeatsMap,
    ],
  )

  const createEmptyTicket = useCallback(() => {
    if (!data?.token) {
      clearSession()
      return
    }
    return createLockSeat({
      variables: {
        token: data.token,
        input: {},
        isAdmin: !!adminToken,
      },
    })
  }, [adminToken, clearSession, createLockSeat, data])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return {
    data,
    onSeatClick,
    clearLockedSeat,
    createEmptyTicket,
    seatsArray,
    lockedSeatsMap,
    isSeatUpdating:
      createLockSeatOptions.loading || deleteLockSeatOptions.loading,
  }
}
