import { css } from 'styled-components'

export const transition = ({
  property = 'all',
  duration = '300ms',
  timingFunction = 'ease',
}) => css`
  transition: ${property} ${duration} ${timingFunction};
`

export const fieldReset = css`
  width: 100%;
  border-radius: 0;
  background: transparent;
  box-shadow: none;
  font-size: 1.25rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &::placeholder,
  &::-ms-input-placeholder {
    /* IE11 seems to need the !important flag */
    color: currentColor !important;
    opacity: 0.4 !important;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &::-ms-expand {
    display: none;
  }

  &:-webkit-autofill {
    background: transparent;
    box-shadow: none;
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const dashDecor = css`
  border-bottom: 1px dotted currentColor;
`
