import parse from 'date-fns/parse'
import format from 'date-fns/format'
import isPast from 'date-fns/isPast'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import pl from 'date-fns/locale/pl'

import { API_DATE_FORMAT } from 'setup/consts'

export const isPastHour = (eventDate: string, eventHour: string = '23:59') => {
  const now = new Date()
  const parsed = parse(
    `${eventDate}/${eventHour}`,
    `${API_DATE_FORMAT}/HH:mm`,
    now,
  )

  return isPast(parsed)
}

export const isPastDate = (eventDate: string, eventHour = '23:59') => {
  const now = new Date()

  return isPast(
    parse(`${eventDate}/${eventHour}`, `${API_DATE_FORMAT}/HH:mm`, now),
  )
}

export const isMoreThanMinuteAgo = (dateStart: Date) =>
  differenceInMinutes(dateStart, new Date()) <= 1

export const formatToLongDisplayDate = (date: Date) =>
  format(date, 'iiii dd.MM.yyyy', { locale: pl })
