import React from 'react'
import ReactDOM from 'react-dom'

import App from 'apps/App'
import BookingOverlay from 'components/BookingOverlay'

import { APP_ID } from './consts'

const injectApp = (
  id: string,
  Component: React.ComponentType<any>,
  isFullScreen = false,
  props: any = {},
) => {
  if (document.getElementById(id)) {
    ReactDOM.render(
      <React.StrictMode>
        {isFullScreen ? (
          <BookingOverlay>
            <Component {...props} />
          </BookingOverlay>
        ) : (
          <Component {...props} />
        )}
      </React.StrictMode>,
      document.getElementById(id),
    )
    if (isFullScreen) {
      document.body.setAttribute('style', 'overflow:hidden;')
    }
  }
}

const ejectWidget = (id: string) => {
  const element = document.getElementById(id)
  if (element) {
    ReactDOM.unmountComponentAtNode(element)
    element.remove()
    document.body.setAttribute('style', '')

    const newUrl = window.location.search
      ? `${window.REACT_RETURN_URL()}${window.location.search}`
      : window.REACT_RETURN_URL()

    window.history.replaceState({}, document.title, newUrl)
  }
}

const injectBuyTicketWidget = (
  adminToken?: string,
  eventId?: string,
  onOrderDetailsClick?: (orderId: string) => void,
) => {
  if (!document.getElementById(APP_ID.BOOKING)) {
    const element = document.createElement('div')
    element.id = APP_ID.BOOKING
    document.body.appendChild(element)
  }

  injectApp(APP_ID.BOOKING, App, true, {
    adminToken,
    eventId,
    onOrderDetailsClick,
  })
}

const ejectBookingWidget = () => {
  ejectWidget(APP_ID.BOOKING)
}

export default {
  injectBuyTicketWidget,
  ejectBookingWidget,
}
