import { useContext, useState, useMemo, useCallback } from 'react'

import { toast } from 'react-toastify'
import { generatePath } from 'react-router'

import AppTypeContext from 'providers/AppTypeProvider/context'
import { ROUTES } from 'routes/consts'
import { EventList_events_nodes } from 'setup/api/events/types/EventList'
import { isMoreThanMinuteAgo } from 'utils/date'

export const useEventsApi = (data: EventList_events_nodes[]) => {
  const { type } = useContext(AppTypeContext)
  // We need this so we will rerender component on date change
  const [lastDate, setLastDate] = useState(new Date())
  const items = useMemo(() => {
    return data.map((item) => ({
      ...item,
      detailsUrl: generatePath(ROUTES.EVENT_DETAILS, {
        eventId: item.id,
      }),
    }))
  }, [data])

  const checkDate = useCallback(
    (item: EventList_events_nodes & { detailsUrl: string }) => (
      event: React.MouseEvent,
    ) => {
      setLastDate(new Date())

      if (isMoreThanMinuteAgo(new Date(item.date))) {
        event.preventDefault()
        toast.error('Sprzedaż biletów na to wydarzenie nie jest dostępna.')
        return
      }
      if (type !== 'booking') {
        window.history.replaceState({}, document.title, `/#${item.detailsUrl}`)
        window.REACT_INJECTORS.injectBuyTicketWidget()
      }
    },
    [type],
  )

  return { items, checkDate, lastDate }
}
