import styled from 'styled-components'
import { media } from 'common/MediaQueries'

export const TicketsWrapper = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 2rem 0;
  text-align: center;

  @media ${media.tablet} {
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 4rem 0;
  }
`

export const TicketsFormWrapper = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 2rem 0;

  display: flex;
  justify-content: center;
  max-width: 100%;

  @media ${media.tablet} {
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 4rem auto;

    width: 400px;
  }
`
