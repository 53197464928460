import { SwiperOptions } from 'swiper'

export const ITEMS_COUNT = 120

export const SWIPER_OPTIONS: SwiperOptions = {
  grabCursor: true,

  breakpoints: {
    1920: {
      slidesPerView: 24,
    },
    1800: {
      slidesPerView: 22,
    },
    1500: {
      slidesPerView: 20,
    },
    1366: {
      slidesPerView: 18,
    },
    1200: {
      slidesPerView: 14,
    },
    1024: {
      slidesPerView: 12,
      spaceBetween: 16,
      slidesPerGroup: 1,
    },
  },
}
