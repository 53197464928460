import React from 'react'
import { UseFormMethods } from 'react-hook-form'

import { Label, Wrapper, CheckboxInput, StyledCheckbox } from './styles'

interface ICheckbox {
  name: string
  label: React.ReactNode
  className?: string
  register: UseFormMethods['register']
  error?: { message: string }
  isRequired?: boolean
  onChange?: (value: any) => void
}

const Checkbox = ({
  name,
  label,
  register,
  error,
  isRequired,
  className,
  onChange,
}: ICheckbox) => {
  return (
    <Wrapper htmlFor={name} hasError={!!error} className={className}>
      <Label>{label}</Label>
      <CheckboxInput
        onChange={onChange}
        id={name}
        type="checkbox"
        name={name}
        ref={register(
          isRequired ? { required: 'Pole jest wymagane' } : undefined,
        )}
      />
      <StyledCheckbox />
    </Wrapper>
  )
}

export default Checkbox
