import { gql } from '@apollo/client'
import { ORDER_FRAGMENT } from './fragments'

export const ORDER_DETAILS = gql`
  ${ORDER_FRAGMENT}
  query OrderDetails($token: String!) {
    order(token: $token) {
      ...Order
      event {
        id
        date
        location
        seatType
        show {
          name
          descLong
          descShort
          duration
          id
        }
        columnsNumber
      }
      tickets {
        nodes {
          id
          name
          priceGross
          ticketPoolId
          lockedSeat {
            seat {
              id
              label
              order
            }
          }
        }
      }
    }
  }
`
