import styled, { css } from 'styled-components'
import { COLORS } from 'setup/theme'

import { media } from 'common/MediaQueries'

export const DaysContainer = styled.div`
  position: relative;
  margin: 2.5rem 0.5rem 0 0.5rem;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 2rem;
    height: 100%;
    z-index: 1;
    pointer-events: none;
  }

  &::before {
    left: 0;
    background-image: linear-gradient(to right, ${COLORS.black}, transparent);
  }

  &::after {
    right: 0;
    background-image: linear-gradient(to left, ${COLORS.black}, transparent);
  }

  .swiper-wrapper {
    box-sizing: border-box;
  }

  .swiper-slide {
    height: auto;
  }

  @media ${media.phone} {
    &::before,
    &::after {
      width: 3rem;
    }
  }

  @media ${media.tablet} {
    &::before,
    &::after {
      width: 4rem;
    }
  }
`

export const DayWrapper = styled.div<IDayWrapper>`
  ${(props) => getDayStyles(props)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.75rem 0.5rem;
  width: 4rem;
  height: 5.5rem;
  text-align: center;
  flex-shrink: 0;
  font-size: 0.625rem;

  @media ${media.tablet} {
    ${({ isDisabled }) =>
      isDisabled &&
      css`
        pointer-events: none;
      `}

    ${({ isSelected }) =>
      !isSelected &&
      css`
        cursor: pointer;

        :hover {
          border-color: ${COLORS.lightGrey};
        }
      `};
  }
`

export const DaysList = styled.div`
  display: flex;
  max-width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 1rem;

  ${DayWrapper} {
    margin: 0 0.5rem;
  }
`

interface IDayWrapper {
  isSelected: boolean
  isDisabled: boolean
}

const getDayStyles = ({ isDisabled, isSelected }: IDayWrapper) => {
  let color = isDisabled ? COLORS.grey2 : 'currentColor'

  let borderWidth = '1'

  if (isSelected) {
    color = COLORS.blue
    borderWidth = '4'
  }
  return css`
    border: ${borderWidth}px solid currentColor;
    color: ${color};
  `
}

export const DayItem = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

export const DayNumber = styled.span`
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

export const Day = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  top: 0.75rem;
`

export const Month = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0.75rem;
`
