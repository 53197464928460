import { gql } from '@apollo/client'

export const LOCKED_SEAT_FRAGMENT = gql`
  fragment LockedSeat on LockedSeat {
    id
    eventId
    type
    seatId
  }
`
