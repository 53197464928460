import React from 'react'

import SettingsContext from './context'

type SettingsProviderT = {
  children: React.ReactNode
  onOrderDetailsClick?: (orderId: number) => void
}

const SettingsProvider = ({
  children,
  onOrderDetailsClick,
}: SettingsProviderT) => {
  return (
    <SettingsContext.Provider value={{ onOrderDetailsClick }}>
      {children}
    </SettingsContext.Provider>
  )
}

export default SettingsProvider
