import { gql } from '@apollo/client'

import { SESSION_FRAGMENT } from './fragments'

export const RETRIEVE_SESSION = gql`
  ${SESSION_FRAGMENT}
  mutation RetrieveSession($token: String, $eventId: Int!, $isAdmin: Boolean!) {
    retrieveSession(token: $token, eventId: $eventId) {
      ...Session
    }
  }
`
