import styled, { css } from 'styled-components'
import { COLORS } from 'setup/theme'
import { transition } from 'common/mixins'
import { ButtonType, ButtonSize } from './consts'
import { media } from 'common/MediaQueries'

interface IButtonProps {
  btnType?: ButtonType
  btnSize?: ButtonSize
}

export const Button = styled.button<IButtonProps>`
  width: 100%;
  line-height: 1.5;
  border: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  outline: 0;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  ${transition}

  ${({ btnType }) =>
    btnType === ButtonType.default &&
    css`
      padding: 1rem 1.5rem;
      color: ${COLORS.white};
      background-color: ${COLORS.blue};
      text-transform: uppercase;
      font-weight: 600;

      :hover {
        background-color: ${COLORS.blueHover};
      }

      :disabled {
        background: ${COLORS.grey3};
      }
    `}

    ${({ btnType }) =>
      btnType === ButtonType.simple &&
      css`
        padding: 0.25rem 0;
        font-size: 0.75rem;
        line-height: 1.5;
        text-align: left;
      `}

    ${({ btnType }) =>
      (btnType === ButtonType.simpleSmall || btnType === ButtonType.simple) &&
      css`
        color: ${COLORS.blue};
        border-bottom: 1px dotted currentColor;

        :disabled {
          display: none;
        }
      `}

    ${({ btnSize }) =>
      btnSize === ButtonSize.small &&
      css`
        min-height: 3.125rem;
        font-size: 1rem;
      `}

    ${({ btnSize }) =>
      btnSize === ButtonSize.default &&
      css`
        min-height: 4.5rem;
        font-size: 1.25rem;
      `}

  :active {
    outline: 0;
  }

  :disabled {
    cursor: not-allowed;
  }

  @media ${media.phone} {
    width: auto;

    ${({ btnType }) =>
      btnType === ButtonType.simple &&
      css`
        font-size: 1rem;
      `}


    ${({ btnSize }) =>
      btnSize === ButtonSize.small &&
      css`
        min-width: 10rem;
      `}

    ${({ btnSize }) =>
      btnSize === ButtonSize.default &&
      css`
        min-width: 20.5rem;
      `}


  }

  @media ${media.tablet} {
    ${({ btnSize }) =>
      btnSize === ButtonSize.small &&
      css`
        min-width: 11rem;
      `}

    ${({ btnType }) =>
      btnType === ButtonType.default &&
      css`
        &:hover:not(:disabled) {
          background-color: ${COLORS.white};
          color: ${COLORS.blueHover};
        }
      `}

    ${({ btnType }) =>
      (btnType === ButtonType.simpleSmall || btnType === ButtonType.simple) &&
      css`
        &:hover {
          border-color: transparent;
        }
      `}
  }
`
