import styled from 'styled-components'
import { COLORS } from 'setup/theme'
import { media } from 'common/MediaQueries'

import Container from 'components/Container'
import { DirectorWrapper } from 'components/Director/styles'
import { transition } from 'common/mixins'

export const Action = styled.div`
  button {
    white-space: nowrap;
  }
`

export const Room = styled.div``

export const Content = styled.div``

export const EventBody = styled.div``

export const Hour = styled.div``

export const EventDetails = styled.li<{ column?: boolean }>`
  text-align: left;
  display: flex;
  justify-content: center;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  width: 100%;
  margin: 0 auto;
  ${({ column }) =>
    column &&
    `
    .c-side-box__content:not(:last-child) {
      margin-bottom: 0;
    }
  `};
`

export const StreamLabel = styled.p`
  display: block;
  color: ${COLORS.blue};
  text-transform: uppercase;
  position: relative;
  padding-left: 1.375rem;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-top: 0.375rem;
  ${transition}

  &:hover {
    color: ${COLORS.grey2};
  }

  &::before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateY(-1px);
    left: 0;
    display: flex;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: currentColor;
    content: '';
    width: 0.75rem;
    height: 0.75rem;
  }

  @media ${media.phone} {
    margin-top: 0;
  }
`

export const Details = styled(Container)`
  display: flex;
  flex-direction: column;

  a {
    &::before {
      display: none;
    }
  }

  @media ${media.phone} {
    align-items: center;
    flex-direction: row;

    ${DirectorWrapper} + span {
      margin-left: 1rem;
    }
  }
`

export const ListWrapper = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  border-top: 1px solid ${COLORS.grey3};
  margin-top: 2rem;

  @media ${media.tablet} {
    margin-top: 4rem;
  }

  ${EventDetails} {
    border-bottom: 1px solid ${COLORS.grey3};
  }

  ${EventBody} {
    position: relative;
    width: 100%;
    display: -ms-grid;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 'hour' 'title' 'spot' 'action';
    padding: 2rem;
    max-width: 83rem;
    margin: 0 auto;

    @media ${media.phone} {
      padding-top: 4rem;
      padding-bottom: 4rem;
      -ms-grid-columns: 1fr 12rem 10rem;
      grid-template-columns: 1fr 12rem 10rem;
      grid-template-areas: 'hour spot action' 'title spot action';
      align-items: center;
    }

    @media ${media.tablet} {
      -ms-grid-columns: 9.25rem 1fr 11.875rem 11rem;
      grid-template-columns: 9.25rem 1fr 11.875rem 11rem;
      grid-template-areas: 'hour title spot action';
    }
  }

  ${Hour} {
    display: flex;
    padding-right: 2rem;
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: hour;
  }

  ${Content} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-area: title;
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    margin-bottom: 1rem;

    @media ${media.phone} {
      -ms-grid-row: 1;
      -ms-grid-column: 2;
      margin-bottom: 0;
    }

    @media ${media.tablet} {
      -ms-grid-row: 1;
      -ms-grid-column: 2;
    }
  }

  ${Room} {
    display: flex;
    grid-area: spot;
    display: flex;
    align-items: center;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    width: 100%;
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    margin-bottom: 1.25rem;

    @media ${media.phone} {
      margin: 0;
      -ms-grid-row: 2;
      -ms-grid-column: 1;
      padding-right: 3rem;
    }

    @media ${media.tablet} {
      min-width: auto;
      padding: 0;
      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }
  }

  ${Action} {
    display: flex;
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    grid-area: action;

    a {
      min-width: 9.5rem;

      &::before {
        display: none;
      }
    }

    @media ${media.phone} {
      -ms-grid-row: 2;
      -ms-grid-column: 2;
    }

    @media ${media.tablet} {
      -ms-grid-row: 1;
      -ms-grid-column: 4;
    }
  }
`
