import React, { useCallback } from 'react'

import { RetrieveSession_retrieveSession_event_eventSeats_nodes } from 'setup/api/session/types/RetrieveSession'

import { SeatBlock, ISeatBlock } from './styles'

const Seat = ({
  onClick,
  seatInfo,
  ...rest
}: ISeatBlock & {
  seatInfo: RetrieveSession_retrieveSession_event_eventSeats_nodes
  onClick: (
    seat: RetrieveSession_retrieveSession_event_eventSeats_nodes,
  ) => void
}) => {
  const onSeatClick = useCallback(() => {
    if (!rest.isTaken && !rest.isDisabled) {
      onClick(seatInfo)
    }
  }, [onClick, rest.isDisabled, rest.isTaken, seatInfo])

  return (
    <SeatBlock data-tip={seatInfo.comments} onClick={onSeatClick} {...rest}>
      {seatInfo.label}
    </SeatBlock>
  )
}

export default Seat
