import styled from 'styled-components'

export const DirectorWrapper = styled.div`
  display: inline-flex;
  align-items: center;

  span {
    margin-left: 0.375rem;
  }
`
