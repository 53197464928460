import React from 'react'

import EventCard from 'components/EventCard'
import Tickets from 'components/Tickets'
import { TicketsWrapper, TicketsFormWrapper } from './styles'
import { useSessionContext } from 'providers/SessionProvider/hooks'

const TicketsPage = () => {
  const { data } = useSessionContext()

  return (
    <TicketsWrapper>
      {!!data?.event && (
        <EventCard event={data.event} isHighlighted showLocation />
      )}
      {!!data?.event && (
        <TicketsFormWrapper>
          <Tickets withoutSeats showAddButton />
        </TicketsFormWrapper>
      )}
    </TicketsWrapper>
  )
}

export default TicketsPage
