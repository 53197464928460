import { makeMutationHook } from 'setup/api/apollo-utils'

import {
  CREATE_LOCKED_SEAT,
  DELETE_LOCKED_SEAT,
  UPDATE_LOCKED_SEAT,
} from './mutations'
import {
  CreateLockedSeat,
  CreateLockedSeatVariables,
} from './types/CreateLockedSeat'
import {
  DeleteLockedSeat,
  DeleteLockedSeatVariables,
} from './types/DeleteLockedSeat'
import {
  UpdateLockedSeat,
  UpdateLockedSeatVariables,
} from './types/UpdateLockedSeat'

export const useCreateLockedSeatMutation = makeMutationHook<
  CreateLockedSeat,
  CreateLockedSeatVariables
>(CREATE_LOCKED_SEAT)

export const useUpdateLockedSeatMutation = makeMutationHook<
  UpdateLockedSeat,
  UpdateLockedSeatVariables
>(UPDATE_LOCKED_SEAT)

export const useDeleteLockedSeatMutation = makeMutationHook<
  DeleteLockedSeat,
  DeleteLockedSeatVariables
>(DELETE_LOCKED_SEAT)
