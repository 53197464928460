import styled from 'styled-components'
import { COLORS } from 'setup/theme'

export const Label = styled.span`
  line-height: inherit;
  font-size: inherit;
  font-family: inherit;
`

export const StyledRadio = styled.span`
  position: relative;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  background: transparent;
  margin-right: 1rem;
  border: 1px solid ${COLORS.lightGrey};
  border-radius: 50%;

  &::after {
    content: '';
    position: absolute;
    display: none;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: white;
  }
`

export const Wrapper = styled.label<{ hasError: boolean }>`
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  align-items: inherit;
  cursor: pointer;
  ${({ hasError }) => !!hasError && `color: ${COLORS.red};`};
  ${StyledRadio} {
    ${({ hasError }) => !!hasError && `border-color: ${COLORS.red};`};
  }
`

export const RadioInput = styled.input`
  position: absolute;
  top: -100vh;
  left: -100vw;
  opacity: 0;
  height: 0;
  width: 0;
  visibility: hidden;

  &:checked + ${StyledRadio} {
    border-color: ${COLORS.white};

    &::after {
      display: block;
    }
  }
`
