import { ROUTES } from 'routes/consts'

export const NAVIGATION_KEYS = {
  Events: 'events',
  Details: 'details',
  Form: 'form',
  Summary: 'summary',
}

export const navigationItems = [
  {
    path: [ROUTES.EVENTS, ROUTES.EVENTS_SELECTED],
    text: 'Repertuar',
    key: NAVIGATION_KEYS.Events,
  },
  {
    path: [ROUTES.EVENT_DETAILS],
    text: 'Miejsce',
    key: NAVIGATION_KEYS.Details,
  },
  {
    path: [ROUTES.FORM],
    text: 'Formularz',
    key: NAVIGATION_KEYS.Form,
  },
  {
    path: [ROUTES.SUMMARY],
    text: 'Podsumowanie',
    key: NAVIGATION_KEYS.Summary,
  },
]
