import React, {
  memo,
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import { useHistory, generatePath, useParams } from 'react-router-dom'
import add from 'date-fns/add'
import format from 'date-fns/format'
import sub from 'date-fns/sub'
import parse from 'date-fns/parse'
import startOfDay from 'date-fns/startOfDay'
import differenceInDays from 'date-fns/differenceInDays'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperClass from 'swiper/types/swiper-class'
import 'swiper/swiper.scss'

import DayBlock from 'components/DayBlock'
import { DaysContainer, DaysList, DayItem } from 'components/DayBlock/styles'
import Schedule from 'components/Schedule'
import { ROUTES } from 'routes/consts'
import { API_DATE_FORMAT } from 'setup/consts'
import { media } from 'common/MediaQueries'
import { ITEMS_COUNT, SWIPER_OPTIONS } from './const'
import { useEventListQuery } from 'setup/api/events/hooks'
import { EventList_events_nodes } from 'setup/api/events/types/EventList'
import { SortOrder } from 'setup/api/types/globalTypes'
import { EventStatus } from 'setup/api/types/globalTypes'

type EventsByDatesT = Record<string, EventList_events_nodes[]>
type DateRangeT = { date: Date; label: string }

const startDate = startOfDay(new Date())

const filters = {
  variables: {
    orderBy: [{ date: SortOrder.ASC }],
    filter: {
      date: {
        gt: startDate,
      },
      status: EventStatus.PUBLISHED,
    },
  },
}

const Events = () => {
  const mediaQueryListener = useMemo(() => matchMedia(`${media.tablet}`), [])
  const [isDesktop, setIsDesktop] = useState(mediaQueryListener.matches)
  const history = useHistory()
  const { date } = useParams<{ date: string }>()

  const { data, loading } = useEventListQuery(filters)

  const [initialRange] = useState<DateRangeT[]>(() => {
    const today = new Date()
    const yesterday = sub(today, { days: 1 })

    return new Array(ITEMS_COUNT + 1).fill(yesterday).map((item, index) => {
      const newDate = add(item, { days: index })
      return {
        date: newDate,
        label: format(newDate, API_DATE_FORMAT),
      }
    })
  })

  const [range, setRange] = useState<DateRangeT[]>(initialRange)

  const sliderRef = useRef<SwiperClass | null>(null)

  const eventByDates: EventsByDatesT = useMemo(() => {
    if (data && data.events?.nodes) {
      return data.events.nodes.reduce((result, item) => {
        const parsedDate = format(new Date(item.date), API_DATE_FORMAT)

        return {
          ...result,
          [parsedDate]: [...(result[parsedDate] || []), item],
        }
      }, {} as EventsByDatesT)
    }
    return {}
  }, [data])

  const updateRange = useCallback(
    (newDate: string) => {
      const currentItemIndex = initialRange.findIndex(
        (item) => item.label === newDate,
      )

      if (currentItemIndex < 2) {
        setRange(initialRange)
      } else {
        setRange(initialRange.slice(currentItemIndex - 1))
      }
    },
    [initialRange],
  )

  // Setup the initial date or move from past to today and set range correctly
  useEffect(() => {
    const today = new Date()

    const isDateFromPast =
      differenceInDays(parse(date, API_DATE_FORMAT, today), today) < 0

    if (data && data.events?.nodes?.length && data.events?.nodes[0].date) {
      const newDate = format(
        new Date(data.events.nodes[0].date),
        API_DATE_FORMAT,
      )

      updateRange(newDate)

      if (!date || isDateFromPast) {
        history.replace(generatePath(ROUTES.EVENTS_SELECTED, { date: newDate }))
      }
    } else {
      updateRange(initialRange[1]!.label)
    }
  }, [data, date, history, initialRange, updateRange])

  const updateSlider = useCallback(() => {
    setIsDesktop(!!mediaQueryListener.matches)
  }, [mediaQueryListener.matches])

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.update()
    }
  }, [range])

  useEffect(() => {
    window.addEventListener('resize', updateSlider)

    return () => {
      window.removeEventListener('resize', updateSlider)
    }
  }, [updateSlider])

  const changeDate = useCallback(
    (newDate: Date) => {
      history.push(
        generatePath(ROUTES.EVENTS_SELECTED, {
          date: format(newDate, API_DATE_FORMAT),
        }),
      )
    },
    [history],
  )
  const selectedIndex = useMemo(
    () =>
      range.findIndex((item) => format(item.date, API_DATE_FORMAT) === date),
    [date, range],
  )

  const setSliderRef = useCallback((swiper) => {
    sliderRef.current = swiper
  }, [])

  return (
    <>
      <DaysContainer>
        {isDesktop ? (
          <Swiper {...SWIPER_OPTIONS} onSwiper={setSliderRef}>
            {range.map((item, index) => (
              <SwiperSlide key={item.label}>
                <DayItem>
                  <DayBlock
                    onSelect={changeDate}
                    isDisabled={loading || !eventByDates[item.label]}
                    isSelected={selectedIndex === index}
                    date={item.date}
                  />
                </DayItem>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <DaysList>
            {range.map((item, index) => (
              <DayItem key={item.label}>
                <DayBlock
                  onSelect={changeDate}
                  isSelected={selectedIndex === index}
                  isDisabled={loading || !eventByDates[item.label]}
                  date={item.date}
                />
              </DayItem>
            ))}
          </DaysList>
        )}
      </DaysContainer>
      {!!date && (
        <Schedule day={date} events={eventByDates[date]} loading={loading} />
      )}
    </>
  )
}

export default memo(Events)
