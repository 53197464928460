import styled from 'styled-components'
import { media } from 'common/MediaQueries'
import { COLORS } from 'setup/theme'

import { SeatBlock, Space, RowNumber } from 'components/Seat/styles'

export const SeatsPickerHead = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  @media ${media.phone} {
    justify-content: flex-start;
    margin-left: 3.25rem;
  }

  @media ${media.tablet} {
    justify-content: center;
    margin-bottom: 3rem;
    margin-left: 0;
  }
`

export const SeatPickerContent = styled.div`
  margin-left: -1rem;
  margin-right: -1rem;
  width: calc(100% + 2rem);
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: ${COLORS.blue} ${COLORS.grey3};
  padding-bottom: 1rem;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 0.75rem;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background-color: ${COLORS.grey3};
    -webkit-box-shadow: 0 0 1px ${COLORS.grey3};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.blue};
    -webkit-box-shadow: 0 0 1px ${COLORS.blue};
  }

  ${Space} + ${Space} + ${RowNumber} {
    margin-left: 2.875rem;
  }

  @media ${media.phone} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 0.25rem;
    min-height: 17.625rem;
    overflow-x: auto;
  }

  @media ${media.tablet} {
    ${Space} + ${Space} + ${RowNumber} {
      margin-left: 3rem;
    }
  }

  @media ${media.desktop} {
    ${SeatBlock} + ${Space} + ${Space} {
      display: block;
    }

    ${Space} + ${Space} + ${RowNumber} {
      margin-left: 1rem;
    }
  }
`
