import React, { memo } from 'react'

import ArrowUp from 'components/Icons/ArrowUp'
import ArrowDown from 'components/Icons/ArrowDown'
import { UseFormMethods } from 'react-hook-form'
import { ErrorMessage } from 'components/Input/styles'
import { TextBody } from 'components/Typography/TextBody'

import {
  SelectField,
  SelectWrapper,
  SelectControl,
  DecorArrows,
} from './styles'

interface ISelectProps {
  name: string
  value?: string
  label: React.ReactNode
  className?: string
  register: UseFormMethods['register']
  error?: { message: string }
  isRequired?: boolean
  onChange?: (value: any) => void
  options: { value: string; label: string }[]
}

const Select = ({
  name,
  label,
  register,
  options,
  error,
  value,
  onChange,
  isRequired,
}: ISelectProps) => {
  return (
    <SelectWrapper className="flex wrap">
      <TextBody className="full-w mar-b-1">{label}</TextBody>
      <SelectControl>
        <SelectField
          name={name}
          onChange={onChange}
          className={`full-w mar-b-1 ${value !== '-1' ? 'active' : ''}`}
          ref={register({
            required: isRequired ? 'Pole jest wymagane' : false,
          })}
        >
          {options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </SelectField>
        <DecorArrows>
          <ArrowUp />
          <ArrowDown />
        </DecorArrows>
      </SelectControl>
      {!!error && <ErrorMessage>{error.message}</ErrorMessage>}
    </SelectWrapper>
  )
}

export default memo(Select)
