import React, { useMemo, useState, ChangeEvent } from 'react'
import { UseFormMethods } from 'react-hook-form'

import {
  TextareaField,
  Label,
  TextareaWrapper,
  TextareaControl,
  ErrorMessage,
} from './styles'

interface ITextarea {
  name: string
  label: React.ReactNode
  type?: string
  register: UseFormMethods['register']
  error?: { message: string }
  className?: string
  isRequired?: boolean
  validate?: (data: any) => string | undefined
  as?: React.ReactNode
}

const Textarea = ({
  className,
  name,
  register,
  error,
  label,
  validate,
  isRequired = true,
  type,
}: ITextarea) => {
  const [inputClass, setInputClass] = useState('')

  const onInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value !== '') {
      setInputClass('active')
    } else {
      setInputClass('')
    }
  }

  const inputProps = useMemo(
    () => ({
      name,
      id: name,
      type,
      className: 'full-w',
      onChange: onInputChange,
    }),
    [name, type],
  )

  return (
    <TextareaWrapper className={className} isInvalid={!!error}>
      <TextareaControl className={inputClass}>
        <TextareaField
          {...inputProps}
          ref={register({
            required: isRequired ? 'Pole jest wymagane' : false,
            validate,
          })}
        />

        <Label htmlFor={name}>{label}</Label>
      </TextareaControl>
      {!!error && <ErrorMessage>{error.message}</ErrorMessage>}
    </TextareaWrapper>
  )
}

export default Textarea
