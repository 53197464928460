import { createContext } from 'react'
import { RetrieveSession_retrieveSession } from 'setup/api/session/types/RetrieveSession'

export interface ISessionContext {
  data: RetrieveSession_retrieveSession | null
  setData: (data: RetrieveSession_retrieveSession | null) => void
  isLoading: boolean
  retrieveSession: (eventId: string) => void
  clearSession: VoidFunction
}

const SessionContext = createContext<ISessionContext>({
  data: null,
  setData: () => null,
  isLoading: false,
  retrieveSession: () => Promise.resolve(),
  clearSession: () => null,
})

export default SessionContext
